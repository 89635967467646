import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import EditingInput from 'shared/components/editingForms/EditingInput';

const PasswordEditModal = ({ close, onSuccess, userId }) => {
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    password: '',
    cpassword: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onAssignSheet = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      await Api.post(`/users/${userId}/update-password`, form);
      if (onSuccess) onSuccess();
      enqueueSnackbar('Password successfully changed!', { variant: 'success' });
      close();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const passMisMatch = form.cpassword && form.password !== form.cpassword;

  return (
    <div className='modal-box change-password-modal d-flex flex-column'>
      <div className='m-header'>
        <h3 className='text-center'>Change Password</h3>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content mt-3 flex-fill'>
        <form
          onSubmit={onAssignSheet}
          className='user-creation-form h-100 justify-content-between d-flex flex-column'
        >
          <ul className='p-0 mb-0'>
            <li className='form-field-group'>
              <div className='col-12 p-0'>
                <label className='m-0'>New Password</label>
              </div>
              <EditingInput
                type='password'
                name='password'
                value={form.password}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter New Password'
                editing={true}
                required={true}
              />
            </li>
            <li className='form-field-group'>
              <div className='col-12 p-0'>
                <label className='m-0'>Repeat New Password</label>
              </div>
              <EditingInput
                type='password'
                name='cpassword'
                value={form.cpassword}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter New Password'
                editing={true}
                required={true}
                hasError={passMisMatch ? 'Passwords mismatch' : ''}
              />
            </li>
          </ul>
          <div className='text-center my-4'>
            <button
              type='submit'
              disabled={fetch || passMisMatch}
              className='btn btn-primary btn-sm-inline text-white px-5'
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordEditModal;
