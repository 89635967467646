import React, { useEffect, useState } from 'react';
import { Api } from 'utils/connectors';
import Header from './components/Header';
import { useSnackbar } from 'notistack';
import { getError, seasonPosIcons } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import medalOrange from 'assets/icons/medal-orange.svg';
import flagOrange from 'assets/icons/flag-orange.svg';
import { IconArrowLeft, IconArrowRight, IconLink, IconPrint } from 'shared/components/Icons';
import ReactModal from 'react-modal';
import SheetsAssignModal from '../Sheets/components/SheetsAssignModal';
import flag from 'assets/icons/flag.svg';
import flagRed from 'assets/icons/flag-red.svg';

const getTeamWLT = point => {
  if (point === null) return null;
  return point === 0 ? 'L' : point === 1 ? 'T' : 'W';
};

const SheetView = ({ match, location, history }) => {
  const stateParams = location.state?.params;
  const [printFetch, setPrintFetch] = useState(false);
  const [assignModalState, setAssignModalState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id, sheetId } = match.params;
  const currentId = sheetId || id;
  const [users, setUsers] = useState([]);
  const [data, setData] = useState();
  const [user, setUser] = useState();
  const [prevNext, setPrevNext] = useState({});

  const getSheetData = async () => {
    try {
      const user_id = sheetId ? id : undefined;
      const { data } = await Api.get(`/sheet/${currentId}/week`, {
        params: { ...stateParams, user_id },
      });
      setData(data.sheets[0]);
      setPrevNext({ prev_sheet_id: data.prev_sheet_id, next_sheet_id: data.next_sheet_id });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUserData = async id => {
    try {
      const { data } = await Api.get(`/users/${id}/details`);
      setUser(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await Api.get(`/users/player-list`);
      setUsers(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSheetPrint = async () => {
    try {
      setPrintFetch(true);
      const { data } = await Api.get(`/sheet/${currentId}/print`);
      window.open(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setPrintFetch(false);
    }
  };

  const goToSheetView = goId => {
    const path = sheetId ? `/users/${id}/sheet/${goId}/view` : `/sheets/${goId}/view`;
    history.push(path, { params: stateParams });
  };

  useEffect(() => {
    if (sheetId) getUserData(id);
    getUsers();
  }, []);

  useEffect(() => {
    getSheetData();
  }, [currentId]);

  if (!data) return <Loading className='has-header' />;

  return (
    <div>
      <Header sheetId={data.sheetId} user={user} />
      <div className='has-header'>
        <div className='sheet-view-head justify-content-between col-12 d-flex align-items-center mb-5 px-5'>
          <div className='d-flex'>
            <button className='view-btn mr-2 bg-white' disabled={printFetch} onClick={onSheetPrint}>
              <IconPrint />
            </button>
            <button
              className={`view-btn mr-2 ${!data.owner_id ? 'bg-white' : ''}`}
              disabled={data.owner_id}
              onClick={() => setAssignModalState(true)}
            >
              <IconLink />
            </button>
          </div>
          <div className='d-flex'>
            <div className='ml-2 icon-indicator'>
              <img src={data.season_leader ? flagRed : flag} alt='medal' />
            </div>
            <div className='ml-2 icon-indicator'>
              <img
                src={seasonPosIcons[data.season_winner === null ? 4 : data.season_winner]}
                alt='medal'
              />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center col-12 pb-5 align-items-center'>
          <button
            onClick={goToSheetView.bind(null, prevNext.prev_sheet_id)}
            disabled={!prevNext.prev_sheet_id}
            className='btn btn-arrows'
          >
            <IconArrowLeft height='20' />
          </button>
          <div className='sheet-view-table mx-3'>
            <div className='table-header d-flex justify-content-between align-items-center'>
              <div>ID:{data.sheetId}</div>
              <h4>{data.poolname}</h4>
              <div>{data.fullname}</div>
            </div>
            <table className='sheet-view-table'>
              <thead>
                <tr>
                  <th>Player 1</th>
                  <th>Team 1</th>
                  <th>
                    (W)in / <br />
                    (L)oss / <br />
                    (T)ie
                  </th>
                  <th>Score</th>
                  <th>Points</th>
                  <th>Team 2</th>
                  <th>
                    (W)in / <br />
                    (L)oss / <br />
                    (T)ie
                  </th>
                  <th>Score</th>
                  <th>Points</th>
                  <th>Team 3</th>
                  <th>
                    (W)in / <br />
                    (L)oss / <br />
                    (T)ie
                  </th>
                  <th>Score</th>
                  <th>Points</th>
                  <th>
                    <u>Week</u> <br />
                    <u>Total</u> <br />
                    <u>Score</u>
                  </th>
                  <th>
                    <u>Season</u> <br />
                    <u>Weekly</u> <br />
                    <u>Points</u>
                  </th>
                  <th>
                    <u>Week</u> <br />
                    <u>Status</u> <br />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.weeks.map((item, i) => {
                  const isWeekWinner = item.week_winner === 1 || item.week_winner2 === 1;
                  return (
                    <tr key={i}>
                      <td>Week {item.week}</td>
                      <td>{item.t1_name}</td>
                      <td className='text-center'>{getTeamWLT(item.t1_points)}</td>
                      <td>{item.t1_score}</td>
                      <td>{item.t1_points}</td>
                      <td>{item.t2_name}</td>
                      <td className='text-center'>{getTeamWLT(item.t2_points)}</td>
                      <td>{item.t2_score}</td>
                      <td>{item.t2_points}</td>
                      <td>{item.t3_name}</td>
                      <td className='text-center'>{getTeamWLT(item.t3_points)}</td>
                      <td>{item.t3_score}</td>
                      <td>{item.t3_points}</td>
                      <td>{item.wts}</td>
                      <td>{item.swp}</td>
                      <td className='px-2 text-center'>
                        {item.week_leader === 1 && !isWeekWinner && (
                          <img className='mx-1' height='17' src={flagOrange} alt='icon' />
                        )}
                        {isWeekWinner && (
                          <img className='mx-1' height='17' src={medalOrange} alt='icon' />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <button
            onClick={goToSheetView.bind(null, prevNext.next_sheet_id)}
            disabled={!prevNext.next_sheet_id}
            className='btn btn-arrows'
          >
            <IconArrowRight height='20' />
          </button>
        </div>
      </div>
      <ReactModal isOpen={!!assignModalState} className='custom-modal'>
        <SheetsAssignModal
          sheet={{
            owner_id: data.owner_id,
            sheet_id: data.sheetId,
          }}
          onSuccessCreate={getSheetData}
          users={users}
          close={() => setAssignModalState(false)}
        />
      </ReactModal>
    </div>
  );
};

export default SheetView;
