import React from 'react';
import Table from 'shared/components/Table';

import medal from 'assets/icons/medal.svg';
import medalOrange from 'assets/icons/medal-orange.svg';
import flag from 'assets/icons/flag.svg';
import flagOrange from 'assets/icons/flag-orange.svg';
import flagRed from 'assets/icons/flag-red.svg';
import eye from 'assets/icons/eye-orange.svg';
import arrow from 'assets/icons/arrow-up.svg';
import { Link } from 'react-router-dom';
import { seasonPosIcons } from 'utils/appHelpers';

const tableConfig = [
  {
    name: null,
    width: '20%',
    headerClassName: 'table-first-header',
    rowClassName: 'd-flex align-items-center text-large',
    value: item => `Sheet ID: ${item.sheetId}`,
  },
  {
    name: 'Winning Pct',
    width: '10%',
    headerClassName: 'text-center',
    rowClassName: 'd-flex align-items-center justify-content-center',
    value: item => <span className='win-pct'>{item.winning_pct}</span>,
  },
  {
    name: 'Points',
    width: '10%',
    headerClassName: 'text-center',
    rowClassName: 'd-flex align-items-center justify-content-center',
    value: item => (
      <div className='compare-block'>
        <span>{item.points_of_week || 0}</span>
        <span>|</span>
        <span>{item.points_of_season || 0}</span>
      </div>
    ),
  },
  {
    name: 'Weekly',
    width: '10%',
    headerClassName: 'text-center',
    rowClassName: 'd-flex align-items-center justify-content-center',
    value: item => <img src={item.week_winner ? medalOrange : medal} alt='medal' />,
  },
  {
    name: 'Season',
    width: '10%',
    headerClassName: 'text-center',
    rowClassName: 'd-flex align-items-center justify-content-center',
    value: item => (
      <img src={seasonPosIcons[item.season_winner === null ? 4 : item.season_winner]} alt='medal' />
    ),
  },
  {
    name: 'Week Leader',
    width: '15%',
    headerClassName: 'text-center',
    rowClassName: 'd-flex align-items-center justify-content-center',
    value: item => <img src={item.week_leader ? flagOrange : flag} alt='medal' />,
  },
  {
    name: 'Season Leader',
    width: '15%',
    headerClassName: 'text-center',
    rowClassName: 'd-flex align-items-center justify-content-center',
    value: item => <img src={item.season_leader ? flagRed : flag} alt='medal' />,
  },
  {
    width: '10%',
    headerClassName: '',
    rowClassName: 'd-flex align-items-center justify-content-center',
    value: item => (
      <Link className='btn table-eye-btn' to={`sheet/${item.id}/view`}>
        <img src={eye} alt='eye' />
      </Link>
    ),
  },
];

const SheetsBlock = ({ sheets, boxToggle, setBoxToggle }) => {
  const sheetsGroup = {};

  sheets.forEach(sheet => {
    sheetsGroup[sheet.poolname] = sheetsGroup[sheet.poolname] || [];
    sheetsGroup[sheet.poolname].push(sheet);
  });

  const getHeaderTotal = (sheets, name) => {
    let result = sheets.find(n => n[name]);
    if (name === 'season_winner') {
      if (!result) return null;
    }
    return result;
  };

  return (
    <div className='sheets-area mt-4'>
      {Object.keys(sheetsGroup)
        .reverse()
        .map((key, i) => {
          const sheets = sheetsGroup[key];
          const isActvie = boxToggle === key;
          return (
            <div
              key={i}
              className={`border-box mb-4 p-4 w-100 drop-menu ${isActvie ? 'active' : 'closed'}`}
            >
              {isActvie && <Table data={sheets} config={tableConfig} name={key} />}
              {!isActvie && (
                <div className='table-first-header d-flex align-items-center justify-content-between pr-5'>
                  <h2>{key}</h2>
                  <div className='d-flex header-icons align-items-center justify-content-between'>
                    <div className='header-icon weekly'>
                      <img
                        src={getHeaderTotal(sheets, 'week_winner') ? medalOrange : medal}
                        alt='medal'
                      />
                    </div>
                    <div className='header-icon season'>
                      <img
                        src={
                          seasonPosIcons[
                            getHeaderTotal(sheets, 'season_winner') === null
                              ? 4
                              : getHeaderTotal(sheets, 'season_winner')
                          ]
                        }
                        alt='medal'
                      />
                    </div>
                    <div className='header-icon w-leader'>
                      <img
                        src={getHeaderTotal(sheets, 'week_leader') ? flagOrange : flag}
                        alt='medal'
                      />
                    </div>
                    <div className='header-icon s-leader'>
                      <img
                        src={getHeaderTotal(sheets, 'season_leader') ? flagRed : flag}
                        alt='medal'
                      />
                    </div>
                  </div>
                </div>
              )}
              <button
                className={`btn border-toggle ${isActvie ? 'active' : ''}`}
                onClick={() => setBoxToggle(isActvie ? null : key)}
              >
                <img src={arrow} alt='arrow' />
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default SheetsBlock;
