import React from 'react';
import SwitcherButtons from 'shared/components/SwitcherButtons';

const onOffButtons = [{ id: false, name: 'Off' }, { id: true, name: 'On' }];

const PoolSettingsStep = ({ form, setForm, isEditing }) => {
  const handleChange = (name, value) => {
    try {
      const newForm = { ...form };
      // eslint-disable-next-line no-eval
      eval(`newForm.${name} = ${value}`);
      setForm(newForm);
    } catch (err) {}
  };

  const onChangeMultiUsers = e => {
    const newForm = { ...form };
    newForm.multi_tier_settings.multi_tier_users = e.target.value;
    setForm(newForm);
  };

  const onFormChange = ({ target }) => {
    const { name, value } = target;
    handleChange(name, value);
  };

  return (
    <div className='px-2'>
      <div className='pool-survival-settings bg-white rounded-5'>
        <div className='settings-item'>
          <div className='d-flex align-items-center mb-3'>
            <label className='mb-0 font-weight-bold p-0'>Teams multi-pick</label>
            <SwitcherButtons
              buttons={onOffButtons}
              activeItem={form.teams_multi_pick}
              onChange={value => handleChange('teams_multi_pick', value)}
              disabled={isEditing}
            />
          </div>
          <p>Allow teams to be picked more than once</p>
          <p className='sub-desc'>Multi-picks is allowed, but not for consecutive weeks</p>
        </div>
        <div className='settings-item'>
          <div className='d-flex align-items-center mb-3'>
            <label className='mb-0 font-weight-bold p-0'>Random pick</label>
            <SwitcherButtons
              buttons={onOffButtons}
              activeItem={form.random_pick}
              onChange={value => handleChange('random_pick', value)}
              disabled={isEditing}
            />
          </div>
          <p>
            System randomly picks a team from week players. The rules of the pool should be taken
            into account.
          </p>
          <p className='sub-desc'>Player looses if he doesn’t pick</p>
        </div>
        <div className='settings-item'>
          <div className='d-flex align-items-center mb-3'>
            <label className='mb-0 font-weight-bold p-0'>Allow Agreement</label>
            <SwitcherButtons
              buttons={onOffButtons}
              activeItem={form.allow_share_prize}
              onChange={value => handleChange('allow_share_prize', value)}
              disabled={isEditing}
            />
          </div>
          <p>Have an option to allow people to come to agreement and split the winning price</p>
          <p className='sub-desc'>Game continues until last survived player</p>
        </div>
        <div className='settings-item'>
          <div className='d-flex align-items-center mb-3'>
            <label className='mb-0 font-weight-bold p-0'>Buy back</label>
            <SwitcherButtons
              disabled={form.multi_tier_settings.available || isEditing}
              buttons={onOffButtons}
              activeItem={form.buy_back_settings.available || false}
              onChange={value => handleChange('buy_back_settings.available', value)}
            />
            <div className='d-flex align-items-center financial-page ml-3'>
              <span className='w-12'>$</span>{' '}
              <input
                type='number'
                name={'buy_back_settings.price'}
                value={form.buy_back_settings.price}
                className='is-small'
                onChange={onFormChange}
                disabled={!form.buy_back_settings.available || isEditing}
              />
            </div>
            <div className='d-flex align-items-center financial-page ml-3'>
              <span className='w-12 mr-1'>📅</span>{' '}
              <input
                type='number'
                name={'buy_back_settings.week'}
                value={form.buy_back_settings.week}
                className='is-small'
                onChange={onFormChange}
                disabled={!form.buy_back_settings.available || isEditing}
              />
            </div>
          </div>
          <p>This means if user losses the week game, can he enter to the game again</p>
          <p className='sub-desc'>
            User can buy back once and immediately after lose. Price and latest allowed week should
            be specified.
          </p>
        </div>
        <div className='settings-item'>
          <div className='d-flex align-items-center mb-3'>
            <label className='mb-0 font-weight-bold p-0'>Multi tier</label>
            <SwitcherButtons
              disabled={form.buy_back_settings.available || isEditing}
              buttons={onOffButtons}
              activeItem={form.multi_tier_settings.available}
              onChange={value => handleChange('multi_tier_settings.available', value)}
            />
            <div className='d-flex align-items-center financial-page ml-3'>
              <span className='w-12'>☆</span>{' '}
              <input
                type='number'
                value={form.multi_tier_settings.multi_tier_users}
                className='is-small'
                onChange={onChangeMultiUsers}
                disabled={!form.multi_tier_settings.available || isEditing}
              />
            </div>
          </div>
          <p>If multi tiers is enabled then buy back can not be enabled.</p>
          <p className='sub-desc'>Multi-tier. Users who lost play together.</p>
        </div>
      </div>
    </div>
  );
};
export default PoolSettingsStep;
