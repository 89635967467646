import React, { useEffect, useState } from 'react';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import Header from './components/Header';
import AccountInfo from './components/AccountInfo';
import SheetsBlock from './components/SheetsBlock';
import AccountNotes from './components/AccountNotes';
import ParticipantsBlock from './components/ParticipantsBlock';

const UserView = ({ match, location }) => {
  const [boxToggle, setBoxToggle] = useState();
  const { id } = match.params;
  const stateParams = location.state?.params;
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [fetch, setFetching] = useState(false);

  const setDefaultBox = data => {
    const { participants, sheets } = data;
    if (participants?.length) {
      setBoxToggle(participants[0]?.pool_name);
    } else if (sheets?.length) {
      setBoxToggle(sheets[sheets.length - 1]?.poolname);
    }
  };

  const getUserData = async () => {
    try {
      setFetching(true);
      const { data } = await Api.get(`/users/${id}/details`, { params: stateParams });
      setDefaultBox(data);
      setData(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [id]);

  if (!data || fetch) return <Loading className='has-header' />;

  return (
    <div>
      <Header user={data.profile} />
      <div className='container has-header sheets-page'>
        <AccountInfo data={data} getUserData={getUserData} stateParams={stateParams} />
        <AccountNotes user={data.profile} getUserData={getUserData} />
        <ParticipantsBlock
          data={data.participants}
          boxToggle={boxToggle}
          setBoxToggle={setBoxToggle}
          userId={id}
        />
        <SheetsBlock sheets={data.sheets} boxToggle={boxToggle} setBoxToggle={setBoxToggle} />
      </div>
    </div>
  );
};

export default UserView;
