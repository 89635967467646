import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { showUsd } from 'utils/appHelpers';

const symbols = [{ id: 1, name: '$' }, { id: 2, name: '%' }];

const AmountSwitcher = ({ value, onValueChange, total, className }) => {
  const firstUpdate = useRef(true);
  const [localValue, setLocalValue] = useState(value || 0);
  const [activeSymbol, setActiveSymbol] = useState(1);

  const onInputChange = ({ target }) => {
    if (localValue === 0) {
      target.value = target.value.replace('0', '');
    }
    setLocalValue(target.value);
    sendChangedValue(target.value);
  };

  const sendChangedValue = (val = localValue) => {
    if (activeSymbol === 1) {
      onValueChange(val || 0);
    } else {
      const res = ((total * val) / 100).toFixed();
      onValueChange(res);
    }
  };

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    sendChangedValue();
  }, [activeSymbol, total]);

  useEffect(() => {
    if (activeSymbol === 1) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className={`amount-switcher align-items-center d-flex ${className || ''}`}>
      <div className='switch-btns d-flex'>
        {symbols.map(item => (
          <button
            className={`btn ${activeSymbol === item.id ? 'active' : ''}`}
            onClick={() => setActiveSymbol(item.id)}
            key={item.id}
            type='button'
          >
            {item.name}
          </button>
        ))}
      </div>
      <input type='number' value={localValue} name='value' onChange={onInputChange} />
      <div className='final-value'>{showUsd(value)}</div>
    </div>
  );
};

export default AmountSwitcher;
