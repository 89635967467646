import React, { useState } from 'react';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { selectCustomStyles } from 'configs';

const SheetsAssignModal = ({ close, sheet, users, onSuccessCreate }) => {
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    user_id: sheet.owner_id,
    sheets: [sheet.sheet_id],
  });

  const onAssignSheet = async () => {
    try {
      setFetch(true);
      await Api.post('/sheet/assign', form);
      if (onSuccessCreate) onSuccessCreate();
      enqueueSnackbar('Sheet successfully assigned!', { variant: 'success' });
      close();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onSelectUser = user => {
    setForm({ ...form, user_id: user.id });
  };

  const btnDisabled = fetch || !form.user_id;

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Sheet Assignment</h5>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content flex-fill'>
        <div className='col-12 user-creation-form'>
          <ul className='pb-0 mb-0 mt-5 col-12'>
            <li className='form-field-group'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Sheet ID #</label>
              </div>
              <p className='px-2 text-large'>{sheet.sheet_id}</p>
            </li>
            <li className='form-field-group'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Player:</label>
              </div>
              <Select
                styles={selectCustomStyles}
                className='multi-select'
                classNamePrefix='multi-select-pre'
                value={users.filter(o => o.id === form.user_id)}
                getOptionLabel={o => `${o.firstname} ${o.lastname}`}
                getOptionValue={o => o.id}
                placeholder={'Not Assigned'}
                onChange={onSelectUser}
                options={users}
                autoFocus={true}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className='m-footer creation-buttons text-center'>
        <button
          className={`btn ${!btnDisabled ? 'btn-next' : ''}`}
          disabled={btnDisabled}
          onClick={onAssignSheet}
        >
          Assign
        </button>
      </div>
    </div>
  );
};

export default SheetsAssignModal;
