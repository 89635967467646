import React from 'react';
import FileSaver from 'file-saver';
import { HOST } from 'configs/host';
import placeholder from 'assets/avatar.png';
import moment from 'moment';
import store from 'redux/store';
import { Provider } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import medal1 from 'assets/icons/medal-1.svg';
import medal2 from 'assets/icons/medal-2.svg';
import medal3 from 'assets/icons/medal-3.svg';
import medalL from 'assets/icons/medal-l.svg';
import medalNull from 'assets/icons/medal-null.svg';
import ConfirmModal from 'shared/components/ConfirmModal';

export const seasonPosIcons = {
  0: medalL,
  1: medal1,
  2: medal2,
  3: medal3,
  4: medalNull,
};

export const getError = err => {
  if (err?.response?.data && typeof err?.response?.data === 'string') {
    return err.response.data;
  }
  if (err?.response?.data?.message) {
    const msg = err.response.data.message;
    return typeof msg === 'string' ? msg : msg.message;
  }
  if (err?.response?.data?.error) {
    const msg = err.response.data.error;
    return typeof msg === 'string' ? msg : msg.message;
  }
  if (err.response?.data) {
    return err.response.data.error || err.response.data.message;
  }
  if (err && err.response && err.response.statusText) {
    return err && err.response && err.response.statusText;
  }
  if (err && err.data && err.data.message) {
    return err.data.message;
  }
  if (err && err.message) {
    return err.message;
  }
  return err;
};

export const bindDuration = duration => {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor(duration / 60 - hours * 60);
  const seconds = Math.floor(duration % 60);
  const round = val => (String(val).length === 1 ? `0${val}` : val);
  return `${round(hours)}:${round(minutes)}:${round(seconds)}`;
};

export const bindDurationMinutes = duration => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  const round = val => (val < 10 ? `0${val}` : val);
  return `${round(minutes)}:${round(seconds)}`;
};

export const arrSum = (arr, key) => {
  return arr.reduce((a, b) => parseInt(a, 10) + (parseInt(b[key], 10) || 0), 0);
};

export const getImageUrl = image => {
  if (!image) return placeholder;
  return image.includes('http') ? `${image}?v=${Date.now()}` : `${HOST.API.URL}/${image}`;
};

export const duplicate = data => JSON.parse(JSON.stringify(data));

export const parseJson = data => {
  if (!data || data.length < 10) return {};
  return JSON.parse(data);
};

export const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    cb(reader.result);
  };
};

export const formatDate = date => {
  if (!date) return '-';
  return moment(date).format('MM/DD/YYYY');
};

export const getEndOfSubs = (date, notExpired) => {
  if (notExpired) return 'No Expiration';
  const day = moment(date).diff(new Date(), 'days');
  const exDate = moment(date).format('MM/DD/YYYY');
  return day <= 0 ? `Expired on ${exDate}` : `${day} days left`;
};

export const saveFile = (fileData, fileName) => FileSaver.saveAs(fileData, fileName);

export const courseSubsStatuses = (id, status) => {
  // 1: 'Accepted',
  // 2: 'Unsubscribed',
  // 3: 'Pendding',
  // 4: 'Declined',
  // 5: 'Expired',
  // 6: 'PendingExtend',
  // 7: 'AcceptedExtended',
  // 8: 'DeclinedExtend',
  const statuses = {
    active: [1, 7],
    pending: [3, 6],
    inactive: [2, 4, 8],
    expired: [5],
    declined: [4, 8],
  };

  return statuses[status].includes(id);
};

export const imageUploadProgress = (setProgress, progressEvent) => {
  setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
};

export const getEpisodeStartTime = (data, index, noFormat) => {
  if (index === 0) return noFormat ? 0 : bindDurationMinutes(0);
  const prevItems = [...data];
  prevItems.splice(index, data.length - index);
  const prevItemsSum = arrSum(prevItems, 'duration');
  return noFormat ? prevItemsSum : bindDurationMinutes(prevItemsSum);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const checkSubsCourseOpened = item => {
  const sub = item.subscription;
  const course = sub && sub.courses && sub.courses[0] && sub.courses[0].course;
  return course && course.isOpened;
};

// export const currency = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'USD',
//   maximumFractionDigits: 0,
// });

export const showUsd = (val = '', hideSymbol) => {
  if (!val) return hideSymbol ? 0 : `$${0}`;
  let result = Number(val).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  if (result.includes('.00')) {
    result = result.substring(0, result.length - 3);
  }
  if (hideSymbol) result = result.replace('$', '');
  return result;
};

export const confirmBox = options => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <Provider store={store}>
        <ConfirmModal onClose={onClose} {...options} />
      </Provider>
    ),
  });
};

export const getMissingOrSurplus = amount => {
  if (amount === 0) return null;

  return {
    text: amount > 0 ? 'Surplus' : 'Deficit',
    className: amount > 0 ? 'result-surplus' : 'result-missing',
    parentClassName: amount > 0 ? 'success' : 'wrong',
    amount,
  };
};

export const getGameState = currentWeekData => {
  return currentWeekData === undefined || currentWeekData.points === null
    ? null
    : currentWeekData.points === 0
    ? 'loss'
    : currentWeekData.points === 1
    ? 'tie'
    : 'win';
};
