import React from 'react';
import UserCreationForm from './components/UserCreationForm';
import UserHeader from './components/UserHeader';

const UserNew = () => {
  return (
    <div>
      <UserHeader />
      <div className='col pb-5 has-header'>
        <UserCreationForm />
      </div>
    </div>
  );
};

export default UserNew;
