import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ConfirmModal = ({
  title = 'Warning',
  message,
  cancel = 'Cancel',
  confirm = 'Yes',
  onSuccess,
  onCancel,
  onClose,
  showDissmissBtn,
  component,
  className,
}) => {
  const [fetching, setFetch] = useState(false);
  const options = useSelector(state => state.options);

  const handleCancelClick = async () => {
    if (onCancel) await onCancel();
    onClose();
  };

  const handleConfirmClick = async () => {
    if (fetching) return;
    setFetch(true);
    await onSuccess(options);
    setFetch(false);
    onClose();
  };

  return (
    <div className={`confirm-modal text-center ${className || ''}`}>
      <div className='modal-header border-0 d-flex justify-content-center pt-6 px-8'>
        <h4>{title}</h4>
        {showDissmissBtn && <button className='modal-dismiss btn close-icon' onClick={onClose} />}
      </div>
      <div className='modal-body p-3'>
        <p className='text-xlg mt-3 mb-4'>{message}</p>
        {component && component}
      </div>
      <div className='modal-footer border-0 px-8 pb-8 d-flex justify-content-between'>
        <button className='btn btn-gray' onClick={handleCancelClick}>
          {cancel}
        </button>
        <button className='btn btn-primary' disabled={fetching} onClick={handleConfirmClick}>
          {confirm}
        </button>
      </div>
    </div>
  );
};

export default ConfirmModal;
