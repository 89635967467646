import React from 'react';
import Header from './components/Header';
import survivalIcon from 'assets/pool_survival.png';
import survivalCover from 'assets/survival_cover.jpg';
import qp3Icon from 'assets/pool_qp3.png';
import qp3Cover from 'assets/qp3_cover.jpg';
import { useHistory } from 'react-router';

const PoolsNewSelection = () => {
  const history = useHistory();

  const onBlockClick = path => {
    history.push(path);
  };

  return (
    <div className='pool-new-selection'>
      <Header />
      <div className='has-header'>
        <div className='d-flex px-10 pt-22'>
          <div className='col-6 mt-12'>
            <div
              className='pool-type-block rounded-5'
              tabIndex={'-1'}
              role='button'
              onClick={onBlockClick.bind(null, '/pools/survival/new')}
            >
              <img src={survivalIcon} className='pool-icon' alt='icon' />
              <img src={survivalCover} className='pool-cover' alt='icon' />
              <p>Survival Pool</p>
            </div>
          </div>
          <div className='col-6 mt-12'>
            <div
              className='pool-type-block rounded-5'
              tabIndex={'-1'}
              role='button'
              onClick={onBlockClick.bind(null, '/pools/qp3/new')}
            >
              <img src={qp3Icon} className='pool-icon' alt='icon' />
              <img src={qp3Cover} className='pool-cover' alt='icon' />
              <p>QP3 Pool</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolsNewSelection;
