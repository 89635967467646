/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError, showUsd } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import ReactModal from 'react-modal';
import {
  IconDraw,
  IconEdit,
  IconEye,
  IconPlus,
  IconReport,
  IconSchedule,
  IconStats,
} from 'shared/components/Icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import survivalWin from 'assets/survival_win.svg';
import survivalLose from 'assets/survival_lose.svg';
import survivalLosses from 'assets/survival_losses.svg';
import survivalLeft from 'assets/survival_left.svg';
import AddPlayerModal from './components/AddPlayerModal';
import DrawModal from './components/DrawModal';
import TierPlayersModal from './components/TierPlayersModal';
import survivalPoolImg from 'assets/survival_pool.png';

const PooSurvivalView = ({ match }) => {
  const history = useHistory();
  const [modalState, setModalState] = useState(false);
  const [drawModalState, setDrawModalState] = useState(false);
  const [tierPlayerModalState, setTierPlayerModalState] = useState({ state: false, tierID: null });
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [users, setUsers] = useState([]);

  const getPoolData = async id => {
    try {
      const { data } = await Api.get(`/pool/${id}?type=survival`);
      setData(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await Api.get(`/users/player-list`);
      const res = data.map(item => ({
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
      }));
      setUsers(res);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onGoReports = e => {
    e?.preventDefault();
    history.push('/reports', { poolId: id });
  };

  useEffect(() => {
    if (id) getPoolData(id);
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    getUsers();
  }, []);

  if (!data) return <Loading className='has-header' />;

  const drawAvailable = data?.pool_config?.multi_tier_settings?.available;

  return (
    <div className='pool-view-page is-survival'>
      <Header name={data.pool_config.name} />
      <div className='has-header pb-5'>
        <div className='pool-cover justify-content-between d-flex'>
          <div>
            <h3>{data.pool_config.name}</h3>
            <h4>Season Week {data.week > 18 ? 18 : data.week} / 18</h4>
            {data?.pool_config?.allow_postseason && (
              <h4>Play Off Week {data.week > 18 ? data.week - 18 : 0} / 4</h4>
            )}
            <button className='btn btn-primary d-block mt-6' onClick={() => setModalState(true)}>
              <IconPlus /> Add Player
            </button>
            {drawAvailable && (
              <button
                className='btn border-primary d-block bg-white d-flex align-items-center justify-content-center'
                onClick={() => setDrawModalState(true)}
              >
                <IconDraw className='mr-2' /> Draw
              </button>
            )}
          </div>
          <div>
            <Link to={`/pools/survival/${id}/edit`} className='btn cover-btn'>
              <IconEdit color={'#fff'} />
            </Link>
            <br />
            <Link to='/schedule' className='btn cover-btn'>
              <IconSchedule color={'#fff'} />
            </Link>
            <br />
            <a href='#' onClick={onGoReports} className='btn cover-btn'>
              <IconReport color={'#fff'} />
            </a>
          </div>
          <div className='survival-pool-cover-title-img'>
            <img src={survivalPoolImg} alt='pool' />
          </div>
        </div>
        <div className='pool-survival-winners'>
          <div className='win-header green'>
            Participants <span>{data.participants_stats?.total}</span>
          </div>
          <div className='d-flex win-columns'>
            <div className='col'>
              <img src={survivalWin} alt='icon' />
              <p>Undefeated</p>
              <div className='d-flex align-items-center justify-content-center'>
                <button
                  className='pool-view-btn'
                  title='Sheet Assignment'
                  onClick={() => setTierPlayerModalState({ state: true, tierID: 1 })}
                >
                  <IconEye />
                </button>
                <span>players: {data.participants_stats.undefeated.count}</span>
              </div>
            </div>
            {data.participants_stats.tier.map((item, i) => {
              const isFirst = i === 0;
              return (
                <div className='col' key={i}>
                  <img src={isFirst ? survivalLose : survivalLosses} alt='icon' />
                  <p>
                    {i + 1} {isFirst ? 'Loss' : 'Losses'}
                  </p>
                  <div className='d-flex align-items-center justify-content-center'>
                    <button
                      className='pool-view-btn'
                      title='Sheet Assignment'
                      onClick={() => setTierPlayerModalState({ state: true, tierID: i + 2 })}
                    >
                      <IconEye />
                    </button>
                    <span>players: {item.count}</span>
                  </div>
                </div>
              );
            })}
            <div className='col'>
              <img src={survivalLeft} alt='icon' />
              <p>Left</p>
              <div className='d-flex align-items-center justify-content-center'>
                <button
                  className='pool-view-btn'
                  title='Sheet Assignment'
                  onClick={() => setTierPlayerModalState({ state: true, tierID: -1 })}
                >
                  <IconEye />
                </button>
                <span>players: {data.participants_stats.left.count}</span>
              </div>
            </div>
          </div>
          <div className='win-header green'>Prize</div>
          <div className='d-flex win-columns green'>
            <div className='col'>
              <span>{showUsd(data.participants_stats.undefeated.prize)}</span>
            </div>
            {data.participants_stats.tier.map((item, i) => {
              return (
                <div className='col' key={i}>
                  <span>{showUsd(item.prize)}</span>
                </div>
              );
            })}
            <div className='col'>
              <span>{showUsd(data.participants_stats.left.prize)}</span>
            </div>
          </div>
        </div>
        <div className='text-center mt-4'>
          <Link className='btn btn-primary' to={`/pools/survival/${id}/stats`}>
            <IconStats className='mr-1' />
            See Stats
          </Link>
        </div>
      </div>
      <ReactModal isOpen={modalState} className='custom-modal'>
        <AddPlayerModal
          poolId={id}
          users={users}
          setUsers={setUsers}
          close={() => setModalState(false)}
          onSuccessCreate={() => getPoolData(id)}
        />
      </ReactModal>
      <ReactModal isOpen={drawModalState} className='custom-modal'>
        <DrawModal pool={data} poolId={id} close={() => setDrawModalState(false)} />
      </ReactModal>
      <ReactModal isOpen={tierPlayerModalState.state} className='custom-modal'>
        <TierPlayersModal
          pool={data}
          poolId={id}
          tierID={tierPlayerModalState.tierID}
          close={() => setTierPlayerModalState({ state: false, tierID: null })}
        />
      </ReactModal>
    </div>
  );
};

export default PooSurvivalView;
