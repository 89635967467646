import React from 'react';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import { showUsd } from 'utils/appHelpers';

const AmountToggle = ({
  name,
  value,
  className,
  toggle,
  onToggleChange,
  onValueChange,
  noCheckArea,
  disabled,
  nameClassName = 'pl-2',
  inputSymbol = '$',
}) => {
  return (
    <div className={`amount-toggle d-flex align-items-center ${className || ''}`}>
      <div className='d-flex first-section align-items-center'>
        {!noCheckArea && (
          <div className='checkbox-area'>
            {onToggleChange && (
              <EditingCheckBox
                name='wins'
                value={toggle}
                onChange={({ target }) => {
                  onToggleChange(target.checked);
                }}
                className='p-0'
                checked={toggle}
                editing={true}
                disabled={disabled}
              />
            )}
          </div>
        )}
        <div className={nameClassName}>
          <label className='m-0'>{name}</label>
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <span className='w-12'>{inputSymbol}</span>{' '}
        {onValueChange && (
          <input
            type='number'
            value={value || 0}
            name='value'
            required={toggle}
            disabled={disabled}
            onChange={({ target }) => {
              onValueChange(target.value);
            }}
          />
        )}
        {!onValueChange && <span className='ml-1'>{showUsd(value || 0, true)}</span>}
      </div>
    </div>
  );
};

export default AmountToggle;
