import React from 'react';
import { useState } from 'react';
import { IconArrowDown, IconArrowUp, IconEye } from 'shared/components/Icons';

const PoolTable = ({ data, configs, title, className, hasToggle, itemsClassName, onEyeClick }) => {
  const [boxToggle, setBoxToggle] = useState(false);

  const hideBody = hasToggle ? !boxToggle : false;

  return (
    <div className={`pool-table ${className || ''}`}>
      <div className='p-table-header d-flex align-items-center justify-content-between'>
        {title}
        <div>
          {onEyeClick && (
            <button className={`btn p-1 mr-2 btn-non-shadow`} onClick={onEyeClick}>
              <IconEye color='#fff' height='12' />
            </button>
          )}
          {hasToggle && (
            <button
              className={`btn p-1 btn-non-shadow ${boxToggle ? 'active' : ''}`}
              onClick={() => setBoxToggle(!boxToggle)}
            >
              {!boxToggle ? <IconArrowDown color='#fff' /> : <IconArrowUp color='#fff' />}
            </button>
          )}
        </div>
      </div>

      <div className='p-tabel-body'>
        <ul className='list-unstyled'>
          {data.map((key, i) => {
            if (hideBody && i >= 7) return null;
            return (
              <li key={i} className={`d-flex justify-content-between ${itemsClassName || ''}`}>
                {configs.map((item, o) => {
                  return <div key={o}>{item.value(key)}</div>;
                })}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PoolTable;
