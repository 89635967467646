import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ name, poolId }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/pools/survival'>All Pools</Link>
        <span className='mx-2'>/</span>
        <Link to={`/pools/survival/${poolId}/view`}>{name}</Link>
        <span className='mx-2'>/</span>
        <span>Player Stats</span>
      </div>
    </div>
  );
};

export default Header;
