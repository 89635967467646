import React from 'react';

const UserHeader = () => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex pl-3'>
        <h3 className='header-title'>Add New Player</h3>
      </div>
    </div>
  );
};

export default UserHeader;
