import React, { useState } from 'react';
import ButtonLine from 'shared/components/ButtonLine';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

const UserCreationForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: undefined,
    password: undefined,
    role: 'player',
    phone: undefined,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onBlurHandle = ({ target }) => {
    const { name } = target;
    if (
      (name === 'firstname' || name === 'lastname') &&
      !form.username &&
      form.firstname &&
      form.lastname
    ) {
      setForm({ ...form, username: `${form.firstname}${form.lastname}` });
    }
  };

  const onFormSubmit = async e => {
    setFetch(true);
    e.preventDefault();
    try {
      if (form.username) form.username = form.username.replace(/\s/g, '');
      await Api.post('/users/register', form);
      enqueueSnackbar('User Successfully Registered', { variant: 'success' });
      history.push('/users');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetch(false);
    }
  };

  const passMisMatch = form.cpassword && form.password !== form.cpassword;

  return (
    <form onSubmit={onFormSubmit} className='col-5 user-creation-form mb-5'>
      <ul className='p-0 mb-0'>
        <li className='form-field-group'>
          <div className='col-4 pl-0'>
            <label className='m-0'>First Name</label>
          </div>
          <EditingInput
            type='text'
            name='firstname'
            value={form.firstname}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Name'
            required
            editing={true}
            maxLength='180'
            onBlur={onBlurHandle}
          />
        </li>
        <li className='form-field-group'>
          <div className='col-3'>
            <label className='m-0'>Last Name</label>
          </div>
          <EditingInput
            type='text'
            name='lastname'
            value={form.lastname}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Last Name'
            required
            editing={true}
            onBlur={onBlurHandle}
          />
        </li>
        <li className='form-field-group'>
          <div className='col-3'>
            <label className='m-0'>Phone</label>
          </div>
          <EditingInput
            type='text'
            name='phone'
            value={form.phone}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Phone Number'
            editing={true}
          />
        </li>
        <li className='form-field-group'>
          <div className='col-3'>
            <label className='m-0'>Username</label>
          </div>
          <EditingInput
            type='text'
            name='username'
            value={form.username}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Username'
            required
            editing={true}
          />
        </li>
        <li className='form-field-group'>
          <div className='col-3'>
            <label className='m-0'>Email</label>
          </div>
          <EditingInput
            type='email'
            name='email'
            value={form.email}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Email'
            editing={true}
          />
        </li>
        <li className='form-field-group'>
          <div className='col-3'>
            <label className='m-0'>Password</label>
          </div>
          <EditingInput
            type='password'
            name='password'
            value={form.password}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Password'
            editing={true}
          />
        </li>
        <li className='form-field-group'>
          <div className='col-3'>
            <label className='m-0'>Password</label>
          </div>
          <EditingInput
            type='password'
            name='cpassword'
            value={form.cpassword}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Password Confirmation'
            editing={true}
          />
        </li>
      </ul>
      <div className='d-flex justify-content-end mt-2'>
        <ButtonLine className='btn btn-gray' type='submit' disabled={fetch || passMisMatch}>
          Create
        </ButtonLine>
      </div>
    </form>
  );
};

export default UserCreationForm;
