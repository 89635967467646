import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';

const FooterButtons = ({
  onGoBackStep,
  onGoNextStep,
  activeStep,
  isLastStep,
  fetching,
  disableNext,
}) => {
  return (
    <div
      className={`col-12 px-4 d-flex creation-buttons ${
        isLastStep ? 'justify-content-center' : 'justify-content-between'
      }`}
    >
      <button
        className='btn btn-prev'
        type='button'
        onClick={onGoBackStep}
        disabled={activeStep === 0}
      >
        Back
      </button>
      {!isLastStep && (
        <button
          className={`btn btn-next ${disableNext ? 'disabled' : ''}`}
          type='submit'
          onClick={onGoNextStep}
          disabled={fetching || disableNext}
        >
          {fetching ? <ButtonLoading /> : 'Next'}
        </button>
      )}
    </div>
  );
};

export default FooterButtons;
