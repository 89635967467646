/* eslint-disable no-eval */
import React, { useState } from 'react';
import { IconCalc } from 'shared/components/Icons';
import { getMissingOrSurplus, showUsd } from 'utils/appHelpers';
import AmountSwitcher from './components/AmountSwitcher';
import AmountToggle from './components/AmountToggle';
import ReactModal from 'react-modal';
import CalcModal from './components/CalcModal';

const checkAndPluse = (item, forceAmount) => {
  if (!item) return 0;
  const { amount, is_valid } = item;
  return is_valid ? forceAmount || amount || 0 : 0;
};

const AmountLine = ({ amount, is_valid, forceAmount }) => {
  return is_valid ? <span className='line-2'>{showUsd(forceAmount || amount || 0)}</span> : null;
};

const PoolFinancialStep = ({ form, setForm, matchStarted }) => {
  const [modalState, setModalState] = useState(false);

  const onChangeForm = (name, amount) => {
    try {
      const newForm = { ...form };
      eval(`newForm.${name} = ${amount || null}`);
      setForm(newForm);
    } catch (err) {}
  };

  const updateFormFromModal = changes => {
    setForm({ ...form, ...changes });
  };

  const isTierAvailable = !!form.multi_tier_settings?.available;

  const tiers = isTierAvailable
    ? new Array(form.multi_tier_settings?.multi_tier_users - 0).fill(0)
    : [];
  const tiersSum = isTierAvailable
    ? form.bucket_mgmt?.tier?.reduce((partialSum, a) => partialSum + a, 0)
    : 0;

  const { season } = form.bucket_mgmt;
  const seasonResult =
    +form.bucket_mgmt.undefeated +
    +tiersSum +
    checkAndPluse({ amount: form.entrance_price, is_valid: form.bucket_mgmt.earliest_out });

  const amountDiff = getMissingOrSurplus(season.amount - seasonResult);

  return (
    <div className='px-2 financial-page'>
      <div className='d-flex header-block justify-content-between align-items-center mb-4'>
        <h6 className='color-blue mb-3'>Financial</h6>
        <p className='m-0 d-flex align-items-center'>
          Total Bucket: {showUsd(form.bucket_mgmt.total)}
        </p>
        <button type='button' className='btn p-0' onClick={() => setModalState(true)}>
          <IconCalc />
        </button>
      </div>
      <div className='white-block-info d-flex'>
        <p className='mb-0'>Bucket Amount</p>
        <p className='mb-0'>Final Value</p>
      </div>
      <div className='white-block d-flex align-items-center'>
        <p className='m-0 block-title'>Administrative</p>
        <AmountSwitcher
          total={form.bucket_mgmt.total}
          value={form.bucket_mgmt.administrative_amount}
          onValueChange={onChangeForm.bind(null, 'bucket_mgmt.administrative_amount')}
        />
      </div>
      <div className='d-flex'>
        <div className='col-8 white-block d-flex'>
          <p className='m-0 block-title'>Season</p>
          <div>
            <AmountSwitcher
              total={form.bucket_mgmt.total}
              value={form.bucket_mgmt.season.amount}
              onValueChange={onChangeForm.bind(null, 'bucket_mgmt.season.amount')}
              className='mb-4'
            />
            <AmountToggle
              name='Undefeated'
              className='pt-20 mb-20'
              value={form.bucket_mgmt.undefeated}
              onValueChange={onChangeForm.bind(null, 'bucket_mgmt.undefeated')}
            />
            {tiers.map((item, i) => {
              return (
                <AmountToggle
                  key={i}
                  name={`Tier ${i + 1}`}
                  className='mb-20'
                  value={form.bucket_mgmt?.tier?.[i]}
                  onValueChange={onChangeForm.bind(null, `bucket_mgmt.tier[${i}]`)}
                />
              );
            })}
            <AmountToggle
              name='Earliest Out'
              className='mb-20'
              value={form.entrance_price}
              toggle={form.bucket_mgmt.earliest_out}
              onToggleChange={onChangeForm.bind(null, 'bucket_mgmt.earliest_out')}
            />
          </div>
          <div className='ml-5 d-flex flex-column justify-content-center'>
            <div className='amount-calc-devide'>
              <div className='top-part'>
                <span className='devide-action'>+</span>
                <AmountLine amount={form.bucket_mgmt.undefeated} is_valid={true} />
                {tiers.map((item, i) => {
                  return (
                    <AmountLine key={i} amount={form.bucket_mgmt?.tier?.[i]} is_valid={true} />
                  );
                })}
                <AmountLine amount={form.entrance_price} is_valid={form.bucket_mgmt.earliest_out} />
              </div>
              <span className='result-line' />
              <span
                className={`result d-flex align-items-center ${
                  amountDiff ? amountDiff.parentClassName : ''
                }`}
              >
                {showUsd(seasonResult)}
                {!!amountDiff && (
                  <span className={`${amountDiff.className} text-nowrap ml-1`}>
                    ({amountDiff.text} {showUsd(amountDiff.amount)})
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className='white-block col ml-2'>
          <p className='m-0 block-title'>Participation Fee</p>
          <AmountToggle
            name='Entry Price'
            className='pt-20 mb-20'
            value={form.entrance_price}
            onValueChange={onChangeForm.bind(null, 'entrance_price')}
            noCheckArea
            disabled={matchStarted}
          />
          {form?.buy_back_settings?.available && (
            <AmountToggle
              name='Buy Back'
              className='mb-20'
              value={form.buy_back_amount}
              onValueChange={onChangeForm.bind(null, 'buy_back_amount')}
              noCheckArea
              disabled={true}
            />
          )}
        </div>
      </div>
      <ReactModal isOpen={modalState} className='custom-modal'>
        <CalcModal
          matchStarted={matchStarted}
          initialForm={form}
          updateInitialForm={updateFormFromModal}
          close={() => setModalState(false)}
        />
      </ReactModal>
    </div>
  );
};

export default PoolFinancialStep;
