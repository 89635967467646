import packageJson from './../../package.json';

const generic = {
  production: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.qp3football.com',
    content: 'https://content.qp3football.com',
    isProdution: true,
  },
  stage: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.pooldev.site',
    content: 'https://content.pooldev.site',
    isProdution: false,
  },
  development: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'dev.pooldev.site',
    content: 'https://content-dev.pooldev.site',
    isProdution: false,
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'test' ? 'development' : env;
  return env;
};

const main = generic[getENV() || 'development'];

export const HOST = {
  API: {
    VERSION: packageJson.version,
    URL: `${main.url_protocol}://${main.api_domain}`,
    WS: `${main.ws_protocol}://${main.api_domain}`,
    LEARNER_DOMAIN: main.learner_domain,
    API_PREFIX: 'api/v1/admin',
    API_PUBLIC_PREFIX: 'api/v1',
    AUTH_HEADER: 'Authorization',
    AUTH_PREFIX: 'Bearer',
    CONTENT_URL: main.content,
  },
};

export const GLOBAL = {
  IS_PRODUCTION: main.isProdution,
};
