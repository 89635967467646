import React, { useState } from 'react';
import userIcon from 'assets/icons/large-user.svg';
import { Api } from 'utils/connectors';
import {
  IconArrowLeft,
  IconArrowRight,
  IconEdit,
  IconEye,
  IconSave,
} from 'shared/components/Icons';
import EditingInput from 'shared/components/editingForms/EditingInput';
import ReactModal from 'react-modal';
import PasswordEditModal from './PasswordEditModal';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { useHistory } from 'react-router';
import { GLOBAL } from 'configs';

const AccountInfo = ({ data, getUserData, stateParams }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [showPass, setShowPass] = useState(false);
  const [editPass, setEditPass] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fetch, setFetch] = useState(false);
  const { profile: user } = data;
  const [form, setForm] = useState({
    firstname: user.firstname,
    email: user.email,
    lastname: user.lastname,
    phone: user.phone,
    username: user.username,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onUpdateProfile = async () => {
    try {
      setFetch(true);
      await Api.post(`/users/${user.user_id}/update`, form);
      setIsEdit(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const goToUserView = id => {
    history.push(`/users/${id}/view`, {
      params: stateParams,
    });
  };

  return (
    <div className='d-none d-sm-flex align-items-start'>
      <div className='mr-6'>
        <div className='border-box d-flex align-items-center justify-content-center small-radius user-img-area'>
          <img src={userIcon} alt='user' />
        </div>
        <div className='mt-4 d-flex justify-content-between'>
          <button
            onClick={goToUserView.bind(null, data.prev_user_id)}
            disabled={!data.prev_user_id}
            className='btn btn-arrows'
          >
            <IconArrowLeft height='20' />
          </button>
          <button
            onClick={goToUserView.bind(null, data.next_user_id)}
            disabled={!data.next_user_id}
            className='btn btn-arrows'
          >
            <IconArrowRight height='20' />
          </button>
        </div>
      </div>
      <div className='border-box pb-2 flex-fill d-flex'>
        <ul className='d-flex flex-wrap m-0 p-0 profile-update-form'>
          <li className={`col-6 d-flex align-items-center px-1 ${isEdit ? 'mb-2' : 'mb-4'}`}>
            <div className='col-6 p-0 font-weight-bold'>First Name</div>
            <div className='col-6 pl-0'>
              <EditingInput
                className='p-0'
                name='firstname'
                type='text'
                value={form.firstname || ''}
                placeholder={'Enter First Name'}
                onChange={handleChange}
                editing={isEdit}
                required
              />
            </div>
          </li>
          <li className={`col-6 d-flex align-items-center px-1 ${isEdit ? 'mb-2' : 'mb-4'}`}>
            <div className='col-6 p-0 font-weight-bold'>Email</div>
            <div className='col-6 pl-0'>
              <EditingInput
                className='p-0'
                name='email'
                type='email'
                value={form.email || ''}
                placeholder={'Enter Email'}
                onChange={handleChange}
                editing={isEdit}
                disabled={GLOBAL.IS_PRODUCTION}
                required
              />
            </div>
          </li>
          <li className={`col-6 d-flex align-items-center px-1 ${isEdit ? 'mb-2' : 'mb-4'}`}>
            <div className='col-6 p-0 font-weight-bold'>Last Name</div>
            <div className='col-6 pl-0'>
              <EditingInput
                className='p-0'
                name='lastname'
                type='text'
                value={form.lastname || ''}
                placeholder={'Enter Last name'}
                onChange={handleChange}
                editing={isEdit}
                required
              />
            </div>
          </li>
          <li className={`col-6 d-flex align-items-center px-1 ${isEdit ? 'mb-2' : 'mb-4'}`}>
            <div className='col-6 p-0 font-weight-bold'>Contact No.</div>
            <div className='col-6 pl-0'>
              <EditingInput
                className='p-0'
                name='phone'
                type='text'
                value={form.phone || ''}
                placeholder={'Enter Phone'}
                onChange={handleChange}
                editing={isEdit}
                required
              />
            </div>
          </li>
          <li className='col-6 d-flex align-items-center px-1'>
            <div className='col-6 p-0 font-weight-bold'>Username</div>
            <div className='col-6 pl-0'>
              <EditingInput
                className='p-0'
                name='username'
                type='text'
                value={form.username || ''}
                placeholder={'Enter Username'}
                onChange={handleChange}
                editing={isEdit}
                required
              />
            </div>
          </li>
          <li className='col-6 d-flex align-items-center px-1'>
            <div className='col-6 p-0 font-weight-bold'>Password</div>
            <div className={`col-6 d-flex align-items-center ${isEdit ? 'pr-0' : 'px-0'}`}>
              <div className='col-6 p-0'>
                {showPass ? user.plain_password : <span>*********</span>}
              </div>
              <div className='d-flex col p-0'>
                <button
                  className={`btn p-0 pass-btns btn-non-shadow ${showPass ? 'active' : ''}`}
                  onClick={() => setShowPass(i => !i)}
                >
                  <IconEye />
                </button>
                <button
                  className='btn p-0 pass-btns btn-non-shadow ml-2'
                  onClick={() => setEditPass(i => !i)}
                >
                  <IconEdit />
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div className='flex-fill text-right'>
          <button
            className='btn save-btn'
            disabled={fetch}
            onClick={isEdit ? onUpdateProfile : setIsEdit.bind(null, true)}
          >
            {isEdit ? <IconSave /> : <IconEdit />}
          </button>
        </div>
      </div>
      <ReactModal isOpen={editPass} className='custom-modal'>
        <PasswordEditModal
          onSuccess={getUserData}
          userId={user.user_id}
          close={() => setEditPass(false)}
        />
      </ReactModal>
    </div>
  );
};

export default AccountInfo;
