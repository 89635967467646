/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { IconEye } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import { useHistory } from 'react-router';

const headers = [
  {
    name: 'First Name',
    className: 'pl-3 col-2',
    key: 'firstname',
  },
  {
    name: 'Last Name',
    className: 'col',
    key: 'lastname',
  },
  {
    name: 'Username',
    className: 'col',
    key: 'username',
  },
  {
    name: 'Pool Name',
    className: 'col-2',
    key: 'poolname',
  },
  {
    name: 'Participations',
    className: 'col',
    key: 'participants',
  },
  {
    name: 'Points',
    className: 'col',
    key: 'points',
  },
  {
    name: 'Winner',
    className: 'col',
    key: 'weekly_winner',
  },
  {
    name: '',
    className: 'col-2',
  },
];

const MultiRow = ({ data, rowKey, rowKeySecond }) => {
  return (
    <div>
      {data.map((item, i) => {
        const value = item[rowKey] ? item[rowKey] : item[rowKeySecond];
        return <div key={i}>{value || '-'}</div>;
      })}
    </div>
  );
};

const Lists = ({ list, order, changeOrder, fetch, getParams }) => {
  const history = useHistory();
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  const goUserView = (user, e) => {
    e.preventDefault();
    history.push(`/users/${user.user_id}/view`, {
      params: { ...getParams(true), from: 'users' },
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map((item, i) => {
            return (
              <li key={i} className='d-flex list-item h-not-set'>
                <div className='pl-3 d-flex col-2 align-items-center'>{item.firstname}</div>
                <div className='col d-flex align-items-center'>{item.lastname}</div>
                <div className='col d-flex align-items-center'>{item.username}</div>
                <div className='col-2 d-flex align-items-center text-nowrap'>
                  <MultiRow data={item.pools} rowKey='poolname' rowKeySecond='survival_poolname' />
                </div>
                <div className='col d-flex align-items-center'>
                  <MultiRow data={item.pools} rowKey='sheets' rowKeySecond='participants' />
                </div>
                <div className='col d-flex align-items-center'>
                  <MultiRow data={item.pools} rowKey='points' />
                </div>
                <div className='col d-flex align-items-center'>
                  <MultiRow data={item.pools} rowKey='weekly_winner' />
                </div>
                <div className='col-2 p-2 justify-content-end flex-fill d-flex'>
                  <a className='view-btn mr-2' onClick={goUserView.bind(null, item)} href='#'>
                    <IconEye />
                  </a>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
