import React from 'react';
import { navigation } from './configs';
import { connect } from 'react-redux';
import { logout } from '../../../Auth/actions/index';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { IconUser } from 'shared/components/Icons';

const SideBar = ({ account, logout }) => {
  const location = useLocation();
  return (
    <>
      <div>
        <div className='info-part d-flex flex-column align-items-center text-center'>
          <div className='profile-info mt-4 align-items-center d-flex flex-column'>
            <Link to='/profile' className='pt-5'>
              <div className='avatar border p-2 m-auto'>
                <IconUser />
              </div>
              <span>
                {account.firstname} {account.lastname}
              </span>
            </Link>
            <div role='button' tabIndex='-1' onClick={logout} className='pointer log-out'>
              Log out
            </div>
          </div>
        </div>
        <div className='navigation mt-4'>
          <ul>
            {navigation &&
              navigation.map(nav => {
                const Icon = nav.image ? false : nav.icon;
                const isActive = location.pathname.includes(nav.parentPath || nav.path);
                return (
                  <li className='nav-item' key={nav.name}>
                    <NavLink
                      className={`d-flex w-100 align-items-center ${isActive ? 'active' : ''}`}
                      to={nav.path}
                      activeClassName='active'
                    >
                      <div className='icon-div p-0'>
                        {Icon ? (
                          <Icon
                            className={nav.iconClassName || ''}
                            color={isActive ? '#FF9D00' : '#202020'}
                          />
                        ) : (
                          <img src={nav.icon} height='22' alt='icon' />
                        )}
                      </div>
                      {nav.name}
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ account }) => ({
  account,
});

export default connect(
  mapStateToProps,
  { logout },
)(SideBar);
