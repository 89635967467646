import React from 'react';
import Header from './components/Header';
import Select from 'react-select';
import { useState } from 'react';
import { useEffect } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { selectCustomStyles } from 'configs';
import { allWeeks, reportOptionsQP3, reportOptionsSurvival, weeks } from 'app/Main/configs';
import { useSelector } from 'react-redux';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';

const Reports = ({ location }) => {
  const account = useSelector(state => state.account);
  const initialPoolId = location?.state?.poolId;
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [pools, setPools] = useState([]);
  const [form, setForm] = useState({
    reportType: undefined,
    poolId: initialPoolId || undefined,
    week: account.currentWeek,
    includeFinance: false,
    userId: undefined,
    allWeeks: undefined,
  });

  const onSelectReportType = option => {
    setForm({ ...form, reportType: option.id });
  };

  const onSelectPool = option => {
    setForm({ ...form, poolId: option.pool_id });
  };

  const onSelectWeek = option => {
    setForm({ ...form, week: option.id });
  };

  const onSelectPlayer = option => {
    setForm({ ...form, userId: option.user_id });
  };

  const handleCheckBoxChange = ({ target }) => {
    const { name, checked } = target;
    const newForm = { ...form };
    newForm[name] = checked ? 1 : 0;
    setForm(newForm);
  };

  const getPools = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const qp3Data = await Api.get(`/pool/list?type=qp3`, { params });
      const survivalData = await Api.get(`/pool/list?type=survival`, { params });
      const qp3Pools = qp3Data.data.results.map(i => ({ ...i, type: 'qp3' }));
      const survivalPools = survivalData.data.results.map(i => ({ ...i, type: 'survival' }));
      setPools([...qp3Pools, ...survivalPools]);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getPlayers = async id => {
    try {
      const { data } = await Api.get(`/users/list`, {
        params: { pool_id: id, page: 1, limit: 1000 },
      });
      setUsers(data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onGenerateExport = async () => {
    try {
      setFetch(true);
      const endpoint = isPoolQp3 ? '/report/sheets' : 'report/participants';
      const { data } = await Api.get(endpoint, { params: form });
      window.open(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    getPools();
  }, []);

  useEffect(() => {
    if (form.poolId) {
      setUsers([]);
      getPlayers(form.poolId);
    }
  }, [form.poolId]);

  const activePool = pools?.find(i => Number(i.pool_id) === Number(form.poolId));
  const activePlayer = users?.find(i => Number(i.user_id) === Number(form.userId));
  const isPoolQp3 = activePool?.type === 'qp3';
  const reportTypes = isPoolQp3 ? reportOptionsQP3 : reportOptionsSurvival;
  const weekData = activePool?.allow_postseason ? allWeeks : weeks;
  const activeReport = reportTypes?.find(i => Number(i.id) === Number(form.reportType));
  const activeWeek = weekData?.find(i => Number(i.id) === Number(form.week));

  return (
    <div>
      <Header />
      <div className='col-12 user-creation-form has-header'>
        <ul className='pb-0 mb-0 col-4'>
          <li className='form-field-group'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Pool</label>
            </div>
            <Select
              styles={selectCustomStyles}
              value={activePool}
              className='multi-select'
              classNamePrefix='multi-select-pre'
              getOptionLabel={o => o.poolname}
              getOptionValue={o => o.pool_id}
              placeholder={'Select Pool'}
              onChange={onSelectPool}
              options={pools}
            />
          </li>
          <li className='form-field-group'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Report Type</label>
            </div>
            <Select
              styles={selectCustomStyles}
              className='multi-select'
              classNamePrefix='multi-select-pre'
              getOptionLabel={o => o.name}
              getOptionValue={o => o.id}
              placeholder={'Select Report'}
              onChange={onSelectReportType}
              value={activeReport}
              options={reportTypes}
            />
          </li>
          {form.reportType === 1 && (
            <li className='form-field-group'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Week</label>
              </div>
              <Select
                styles={selectCustomStyles}
                className='multi-select'
                classNamePrefix='multi-select-pre'
                getOptionLabel={o => {
                  if (account.currentWeek === o.id) return `${o.name} (current)`;
                  return o.name;
                }}
                getOptionValue={o => o.id}
                value={activeWeek}
                placeholder={'Select Week'}
                onChange={onSelectWeek}
                options={weekData}
              />
            </li>
          )}
          {form.reportType === 2 && isPoolQp3 && (
            <li className='form-field-group mt-6 is-checkbox'>
              <EditingCheckBox
                name='includeFinance'
                value={form.includeFinance}
                onChange={handleCheckBoxChange}
                className='p-0'
                checked={form.includeFinance}
                editing={true}
              />
              <div className='col-8 pl-0'>
                <label className='m-0'>Include Financial Report</label>
              </div>
            </li>
          )}
          {form.reportType === 4 && !isPoolQp3 && (
            <li className='form-field-group mt-6 is-checkbox'>
              <EditingCheckBox
                name='allWeeks'
                value={form.allWeeks}
                onChange={handleCheckBoxChange}
                className='p-0'
                checked={form.allWeeks}
                editing={true}
              />
              <div className='col-8 pl-0'>
                <label className='m-0 text-nowrap'>
                  Include current and upcoming week selections
                </label>
              </div>
            </li>
          )}
          {(form.reportType === 5 || (!isPoolQp3 && form.reportType === 3)) && (
            <li className='form-field-group'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Player</label>
              </div>
              <Select
                styles={selectCustomStyles}
                className='multi-select'
                classNamePrefix='multi-select-pre'
                getOptionLabel={o => `${o.firstname} ${o.lastname}`}
                getOptionValue={o => o.user_id}
                placeholder={'Select Player'}
                onChange={onSelectPlayer}
                value={activePlayer}
                options={users}
              />
            </li>
          )}
        </ul>
        <div className='col-12 mt-6'>
          <button
            className='btn btn-primary text-white'
            disabled={fetch || !form.reportType || !form.poolId}
            onClick={onGenerateExport}
          >
            Generate
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reports;
