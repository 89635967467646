/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { IconEye, IconLink } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import { useHistory } from 'react-router';
import Loading from 'shared/components/Loading';

const headers = [
  {
    name: 'Sheets ID#',
    className: 'pl-3 col',
    key: 'sheet_id',
  },
  {
    name: 'Owner',
    className: 'col-2',
    key: 'owner',
  },
  {
    name: 'Season',
    className: 'col',
    key: 'season',
  },
  {
    name: 'Pool Name',
    className: 'col-2',
    key: 'poolname',
  },
  {
    name: 'Points',
    className: 'col',
    key: 'points',
  },
  {
    name: 'Score',
    className: 'col',
    key: 'score',
  },
  {
    name: 'Wins',
    className: 'col',
    key: 'winner',
  },
  {
    name: '',
    className: 'col-2',
  },
];

const Lists = ({ list, order, changeOrder, fetch, onSheetAssignClick, getParams }) => {
  const history = useHistory();
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  const goSheetView = (item, e) => {
    e.preventDefault();
    history.push(`/sheets/${item.id}/view`, {
      params: { ...getParams(true), from: 'sheets' },
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map((item, i) => (
            <li key={i} className='d-flex list-item h-not-set'>
              <div className='pl-3 d-flex col align-items-center'>{item.sheet_id}</div>
              <div className='col-2 d-flex align-items-center'>
                {item.owner ? <Link to={`/users/${item.owner_id}/view`}>{item.owner}</Link> : ''}
              </div>
              <div className='col d-flex align-items-center text-nowrap'>{item.season}</div>
              <div className='col-2 d-flex align-items-center'>{item.poolname}</div>
              <div className='col d-flex align-items-center'>{item.points}</div>
              <div className='col d-flex align-items-center'>{item.score}</div>
              <div className='col d-flex align-items-center'>{item.winner}</div>
              <div className='col-2 p-2 justify-content-end flex-fill d-flex'>
                <button
                  className='view-btn mr-2'
                  disabled={item.owner_id}
                  title='Sheet Assignment'
                  onClick={() => onSheetAssignClick(item)}
                >
                  <IconLink />
                </button>
                <a className='view-btn mr-2' href='#' onClick={goSheetView.bind(null, item)}>
                  <IconEye />
                </a>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Lists;
