import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import Select from 'react-select';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import useDebounce from 'shared/hooks/useDebounce';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import ReactModal from 'react-modal';
import SheetsCreateModal from './components/SheetsCreateModal';
import SheetsAssignModal from './components/SheetsAssignModal';
import { selectCustomStyles } from 'configs';
import ButtonFilter from 'shared/components/ButtonFilter';

const Sheets = () => {
  const [modalState, setModalState] = useState(false);
  const [assignModalState, setAssignModalState] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [filterWinner, setFilterWinner] = useState(false);
  const [filterUnassign, setFilterUnassign] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [pools, setPools] = useState([]);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);

  const [poolId, setPoolId] = useState();
  const [order, setOrder] = useState({ order: false, orderKey: 'sheet_id' });
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getParams = noPage => {
    const params = {
      ...order,
      page: page + 1,
      limit: 10,
      pool_id: poolId,
      winner: filterWinner,
      unassigned: filterUnassign,
    };
    if (debouncedSearch) params.search = debouncedSearch;
    if (noPage) {
      delete params.page;
      delete params.limit;
    }
    return params;
  };

  const getData = useCallback(async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/sheet/list`, { params: getParams() });
      setPageCount(Math.ceil(data.count / 10));
      setData(data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  }, [debouncedSearch, enqueueSnackbar, order, page, poolId, filterWinner, filterUnassign]);

  const getPools = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const qp3Data = await Api.get(`/pool/list?type=qp3`, { params });
      const survivalData = await Api.get(`/pool/list?type=survival`, { params });
      const qp3Pools = qp3Data.data.results.map(i => ({ ...i, type: 'qp3' }));
      const survivalPools = survivalData.data.results.map(i => ({ ...i, type: 'survival' }));
      setPools([...qp3Pools, ...survivalPools]);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await Api.get(`/users/player-list`);
      setUsers(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  const onSelectPool = option => {
    setPoolId(option ? option.pool_id : null);
  };

  useEffect(() => {
    getData();
  }, [getData, page, order, poolId, filterWinner, filterUnassign]);

  useEffect(() => {
    getPools();
    getUsers();
  }, []);

  return (
    <div>
      <Header onSearch={handleSearchChange} setModalState={setModalState} />
      <div className='py-3 has-header'>
        <div className='d-flex'>
          <div className='form-field-group col-3 mb-4'>
            <Select
              styles={selectCustomStyles}
              className='multi-select'
              classNamePrefix='multi-select-pre'
              getOptionLabel={o => o.poolname}
              getOptionValue={o => o.pool_id}
              placeholder={'Select Pool'}
              onChange={onSelectPool}
              options={pools}
              isClearable
            />
          </div>
          <div className='mx-1'>
            <ButtonFilter title='Winner' state={filterWinner} changeState={setFilterWinner} />
          </div>
          <div className='mx-1'>
            <ButtonFilter
              title='Unassigned'
              state={filterUnassign}
              changeState={setFilterUnassign}
            />
          </div>
        </div>
        <div className='col-12'>
          <Lists
            list={data}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            onSheetAssignClick={sheet => setAssignModalState(sheet)}
            getParams={getParams}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft color='#C2C2C2' />}
            nextLabel={<IconArrowRight color='#C2C2C2' />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
      <ReactModal isOpen={modalState} className='custom-modal'>
        <SheetsCreateModal
          pools={pools}
          users={users}
          close={() => setModalState(false)}
          onSuccessCreate={getData}
        />
      </ReactModal>
      <ReactModal isOpen={!!assignModalState} className='custom-modal'>
        <SheetsAssignModal
          sheet={assignModalState}
          users={users}
          close={() => setAssignModalState(false)}
          onSuccessCreate={getData}
        />
      </ReactModal>
    </div>
  );
};

export default Sheets;
