import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import useDebounce from 'shared/hooks/useDebounce';
import ListsSurvival from './components/ListsSurvival';
import ListsQp3 from './components/ListsQp3';

const Pools = ({ match }) => {
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);

  const [order, setOrder] = useState({ order: false, orderKey: 'poolname' });
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const isSurvival = match.params?.type === 'survival';

  const getData = useCallback(async () => {
    try {
      setFetch(true);
      const params = { ...order, page: page + 1, limit: 10, type: match.params?.type };
      if (debouncedSearch) params.search = debouncedSearch;
      const { data } = await Api.get(`/pool/list`, { params });
      setPageCount(Math.ceil(data.count / 10));
      setData(data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  }, [debouncedSearch, enqueueSnackbar, order, page, match.params?.type]);

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  useEffect(() => {
    getData();
  }, [getData, page, order, match.params?.type]);

  const Lists = isSurvival ? ListsSurvival : ListsQp3;

  return (
    <div>
      <Header onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <Lists list={data} order={order} changeOrder={setOrder} fetch={fetch} />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft color='#C2C2C2' />}
            nextLabel={<IconArrowRight color='#C2C2C2' />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Pools;
