import React, { useState } from 'react';
import { Api } from 'utils/connectors';
import { IconEdit, IconSave } from 'shared/components/Icons';

const AccountNotes = ({ user, getUserData }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [note, setNote] = useState(user.note || '');

  const onUpdateProfile = async () => {
    try {
      setFetch(true);
      await Api.post(`/users/${user.user_id}/update-note`, { note });
      setIsEdit(false);
    } catch (err) {
      setFetch(false);
    } finally {
      setFetch(false);
    }
  };

  return (
    <div className='d-none d-sm-flex notes-area'>
      <div className='border-box pb-2 flex-fill d-flex'>
        <div className='d-flex flex-fill text-right p-1'>
          <div className='flex-fill pr-10'>
            <textarea
              onChange={e => setNote(e.target.value)}
              name='notes'
              value={note}
              className='form-control'
              disabled={!isEdit}
            />
          </div>
          <button
            className='btn save-btn'
            disabled={fetch}
            onClick={isEdit ? onUpdateProfile : setIsEdit.bind(null, true)}
          >
            {isEdit ? <IconSave /> : <IconEdit />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountNotes;
