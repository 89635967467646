import React, { useState } from 'react';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { selectCustomStyles } from 'configs';
import drawSuccess from 'assets/draw_success.png';
import Loading from 'shared/components/Loading';

const DrawModal = ({ pool, close, poolId }) => {
  const [fetch, setFetch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [users, setUsers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState({
    id: null,
  });

  const onDraw = async () => {
    try {
      setFetch(true);
      await Api.post(`/pool/${poolId}/draw`, { params: { ...form } });
      setSuccess(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onSelectReportType = async option => {
    try {
      setFetch(true);
      setForm({ ...form, id: option.id });
      const users = await Api.get(`/pool/${poolId}/payouts`, { params: { id: option.id } });
      setUsers(users.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const btnDisabled = fetch || !form.id;

  const customTiers = pool.pool_config.bucket_mgmt.tier.map((item, i) => ({
    name: `Tier ${i + 1}`,
    id: i + 2,
  }));

  const tiersList = [{ name: 'Undefeated', id: 1 }, ...customTiers];

  const total = users?.reduce((ac, item) => ac + item.prize, 0);

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Draw Pool</h5>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content flex-fill'>
        {!success && (
          <div className='col-12 user-creation-form'>
            <ul className='pb-0 mb-0 col-12'>
              <li className='form-field-group'>
                <div className='col-4 pl-0'>
                  <label className='m-0 pl-0'>Tier to draw</label>
                </div>
                <Select
                  styles={selectCustomStyles}
                  className='multi-select'
                  classNamePrefix='multi-select-pre'
                  getOptionLabel={o => o.name}
                  getOptionValue={o => o.id}
                  placeholder={'Select Tier'}
                  onChange={onSelectReportType}
                  options={tiersList}
                />
              </li>
              {form.id && (
                <li className='form-field-group'>
                  <div className='col-12 pr-3'>
                    <label className='m-0 pl-0 d-flex aling-items-center justify-content-between'>
                      Players
                      {!!total && <span className='font-weight-bolder'>${total}</span>}
                    </label>
                  </div>
                  {!users.length && !fetch && (
                    <p className='text-center text-black-50'>There is no any Players</p>
                  )}
                  {fetch && <Loading className='mt-1' />}
                  <div className='player-prize-container'>
                    {!!users?.length &&
                      !fetch &&
                      users.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className='player-prize-row d-flex align-items-center justify-content-between'
                          >
                            <p className='mb-0'>{item.owner_name || item.entrance_name}</p>
                            <p className='mb-0 prize'>${item.prize}</p>
                          </div>
                        );
                      })}
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}
        {success && (
          <div className='text-center mt-8'>
            <img src={drawSuccess} width='200' alt='icon' />
            <p className='mt-5'>
              <b>
                We have <span className='text-primary'>4</span> winners in the group{' '}
                <span className='text-primary'>Tier - 1</span>
              </b>
            </p>
          </div>
        )}
      </div>
      <div className='m-footer creation-buttons text-center'>
        <button
          className={`btn ${!btnDisabled ? 'btn-next' : ''}`}
          disabled={btnDisabled || !users.length}
          onClick={success ? close : onDraw}
        >
          {success ? 'OK' : 'Confirm'}
        </button>
      </div>
    </div>
  );
};

export default DrawModal;
