import React from 'react';
import { useHistory } from 'react-router-dom';

import winIcon from 'assets/icons/survival-win.svg';
import loseIcon from 'assets/icons/survival-lose.svg';
import lossesIcon from 'assets/icons/survival-losses.svg';
import backIocn from 'assets/icons/survival-back.svg';
import arrow from 'assets/icons/arrow-up.svg';
import { useSelector } from 'react-redux';

const ParticipantsBlock = ({ userId, data, boxToggle, setBoxToggle }) => {
  const account = useSelector(state => state.account);
  const history = useHistory();

  const onOpenStats = pool => {
    history.push(`/users/${userId}/entries/${pool.pool_id}/view`);
  };

  return (
    <div className='sheets-area mt-6'>
      {data.map((data, i) => {
        const isActvie = boxToggle === data.pool_name;
        return (
          <div
            className={`border-box participants-block mb-4 p-4 w-100 drop-menu ${
              isActvie ? 'active' : 'closed'
            }`}
            key={i}
          >
            {isActvie && (
              <div className='table-first-header'>
                <h2>{data.pool_name}</h2>
                <div className={`table-box mt-4`}>
                  <div className='table-header border-bottom mb-2 pb-1'>
                    <div className='table-row p-0 d-flex'>
                      <div className='table-first-header col p-0'>Name</div>
                      <div className='table-first-header col-1 text-center p-0'>Status</div>
                      <div className='table-first-header col text-center p-0'>Week</div>
                      <div className='table-first-header col p-0'>This Week Selection</div>
                      <div className='table-first-header text-right col p-0'>Score</div>
                      <div className='table-first-header text-right col p-0'>Buyback</div>
                    </div>
                  </div>
                  <div className='table-body'>
                    {data.participants.map((row, i) => {
                      const currentWeek = row.week_data.find(i => i.week === account.currentWeek);
                      const totalScore = row.week_data?.reduce((ac, item) => ac + item.score, 0);
                      const activeWeeks = [...row.week_data].filter(i => i.score);
                      return (
                        <div
                          className='table-row align-items-center py-2 d-flex pointer'
                          tabIndex={-1}
                          role='button'
                          key={i}
                          onClick={onOpenStats.bind(null, data)}
                        >
                          <div className='col p-0 font-weight-bold'>{row.entrance_name}</div>
                          <div className='col-1 p-0 text-center'>
                            {row.undefeated && <img src={winIcon} alt='back' />}
                            {row.tier && <img src={loseIcon} alt='back' />}
                            {row.out && <img src={backIocn} alt='back' />}
                          </div>
                          <div className='col p-0 text-center pool-block-score'>
                            {activeWeeks.length}
                          </div>
                          <div className='col p-0 text-center'>
                            {currentWeek && currentWeek.team}
                            {!currentWeek && (
                              <div className='choose-team-placeholder d-flex align-items-center justify-content-between m-auto'>
                                Choose Team
                                <svg
                                  height='20'
                                  width='20'
                                  viewBox='0 0 20 20'
                                  aria-hidden='true'
                                  focusable='false'
                                >
                                  <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' />
                                </svg>
                              </div>
                            )}
                          </div>
                          <div className='col p-0 text-right pool-block-score text-primary'>
                            {totalScore || 0}
                          </div>
                          <div className='col pl-0 text-right d-flex align-items-center justify-content-end pr-0'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='25'
                              viewBox='0 0 24 25'
                              className='mr-3'
                              fill='none'
                            >
                              <circle cx='12' cy='12.5' r='11.5' fill='#F7F7F7' stroke='#C2C2C2' />
                            </svg>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {!isActvie && (
              <div className='table-first-header d-flex align-items-center justify-content-between pr-5'>
                <h2>{data.pool_name}</h2>
                <div className='d-flex header-icons align-items-center justify-content-between'>
                  <div className='header-icon'>
                    <img src={winIcon} alt='win' />
                  </div>
                  <div className='header-icon'>
                    <img src={loseIcon} alt='lose' />
                  </div>
                  <div className='header-icon'>
                    <img src={lossesIcon} alt='losses' />
                  </div>
                  <div className='header-icon'>
                    <img src={backIocn} alt='back' />
                  </div>
                </div>
              </div>
            )}
            <button
              className={`btn border-toggle ${isActvie ? 'active' : ''}`}
              onClick={() => setBoxToggle(isActvie ? null : data.pool_name)}
            >
              <img src={arrow} alt='arrow' />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ParticipantsBlock;
