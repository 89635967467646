import React from 'react';
import { Link } from 'react-router-dom';

const LinkInfo = ({ title, link, name }) => (
  <p>
    <span className='opc-3'>{title}</span>
    <Link className='link ml-2' to={link}>
      {name}
    </Link>
  </p>
);

const AuthBlock = ({ title, subTitle, children, links, successBlock }) => {
  return (
    <div className='align-items-center auth-block d-flex justify-content-between py-5'>
      <div className='text-center mt-5 col-9'>
        <Link to='/login'>
          <h1>NFL</h1>
        </Link>
        {successBlock ? (
          successBlock
        ) : (
          <>
            {title && <h3 className='title text-center mt-5'>{title}</h3>}
            {subTitle && <p className='opc-3'>{subTitle}</p>}
            <div className='align-items-center d-flex flex-column'>{children}</div>
          </>
        )}
      </div>
      <div className='text-center'>
        {links.reset && <LinkInfo title='Forgot password?' link='/forgot' name='Reset' />}
        {links.signin && <LinkInfo title='Already have an account?' link='/login' name='Sign In' />}
      </div>
    </div>
  );
};

export default AuthBlock;
