import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ sheetId, user }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      {!user || !user.profile ? (
        <div className='d-flex breadcrumbs'>
          <Link to='/sheets'>All Sheets</Link>
          <span className='mx-2'>/</span>
          <span>Sheet ID: {sheetId}</span>
        </div>
      ) : (
        <div className='d-flex breadcrumbs'>
          <Link to='/users'>Players</Link>
          <span className='mx-2'>/</span>
          <Link to={`/users/${user.profile.user_id}/view`}>
            {user.profile.firstname} {user.profile.lastname}
          </Link>
          <span className='mx-2'>/</span>
          <span>Sheet ID: {sheetId}</span>
        </div>
      )}
    </div>
  );
};

export default Header;
