import React from 'react';

const RadioBox = ({ name, label, value, onChange, className, checked }) => {
  return (
    <div className={`align-items-center radioboxradiobox-item d-flex mr-3 ${className || ''}`}>
      <input
        type='radio'
        id={`radio${name}`}
        className='input-custom'
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <label htmlFor={`radio${name}`}>{label}</label>
    </div>
  );
};

export default RadioBox;
