import React, { useCallback, useState } from 'react';
import Header from './components/Header';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { HOST } from 'configs/host';
import { IconPDFSuccess, IconPDFUpload } from 'shared/components/Icons';
import { useDropzone } from 'react-dropzone';
import { Api } from 'utils/connectors';

const Knockout = () => {
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const fileUrl = `${HOST.API.CONTENT_URL}/knockout/ko-pool.pdf`;
  const [file, setfile] = useState();

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles) setfile(acceptedFiles[0]);
  }, []);

  const onStartUpload = async () => {
    try {
      setFetch(true);
      const formData = new FormData();
      formData.append('file', file);
      await Api.post('/knockout/upload', formData);
      enqueueSnackbar('Successfully uploaded', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
      setfile();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });

  return (
    <div>
      <Header />
      <div className='has-header knockout-page col-12 px-10'>
        <div className='current-file'>
          Current file:{' '}
          <a href={fileUrl} target='_blank' rel='noreferrer'>
            ko-pool.pdf
          </a>
        </div>
        <div
          className='file-upload-box d-flex flex-column justify-content-center align-items-center'
          {...getRootProps()}
        >
          {file ? <IconPDFSuccess /> : <IconPDFUpload />}
          <h5>{file ? file.name : 'Upload PDF'}</h5>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drop file here or click this area to open upload window</p>
          )}
        </div>
        <div className='file-upload-btn'>
          <button disabled={fetch || !file} onClick={onStartUpload} className='btn btn-secondary'>
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default Knockout;
