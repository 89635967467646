import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getSelectedStyles } from 'configs';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';

const TeamSelect = ({ id, week, currentWeekData, poolId, onTeamSelectSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showTeamSelect, setShowTeamSelect] = useState(false);
  const [fetching, setFetching] = useState(false);

  const getTeams = async () => {
    try {
      const { data } = await Api.get(`/participant/${id}/teams?week=${week}`);
      return data;
    } catch (err) {
      return [];
    }
  };

  const onSelectTeam = async team => {
    try {
      setFetching(true);
      const body = {
        participant_id: id,
        week: week,
        teams: [team.Key],
      };
      await Api.post(`/pool/${poolId}/pick-teams`, body);
      onTeamSelectSuccess && onTeamSelectSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const activeItem =
    currentWeekData?.team || currentWeekData?.FullName
      ? {
          Key: currentWeekData?.team,
          FullName: currentWeekData?.FullName,
        }
      : null;

  return (
    <div className='w-100 team-select'>
      {!showTeamSelect && (
        <p
          onMouseEnter={() => setShowTeamSelect(true)}
          className='d-flex align-items-center justify-content-between'
        >
          {activeItem?.FullName || 'Select Team'}
          <svg height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false'>
            <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' />
          </svg>
        </p>
      )}
      {showTeamSelect && (
        <AsyncSelect
          cacheOptions
          loadOptions={getTeams}
          defaultOptions
          isSearchable={false}
          styles={getSelectedStyles({ color: '#202020' })}
          menuPortalTarget={document.body}
          className='multi-select'
          classNamePrefix='multi-select-pre'
          getOptionLabel={o => o.FullName}
          getOptionValue={o => o.Key}
          disabled={fetching}
          value={activeItem}
          placeholder={'Select Team'}
          onChange={onSelectTeam}
        />
      )}
    </div>
  );
};

export default TeamSelect;
