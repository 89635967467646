import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import CreateFormSteps from 'shared/components/CreateFormSteps';
import PoolGeneralStep from './components/PoolGeneralStep';
import PoolCompleteStep from './components/PoolCompleteStep';
import PoolFinancialStep from './components/PoolFinancialStep';

const PoolsQP3New = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [matchStarted, setMatchStarted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    activeCases: [],
    franchises: [],
    tags: [],
  });

  const [form, setForm] = useState({
    name: '',
    game: 'NFL',
    season: '',
    weekly_winner_settings: {
      wins: 0,
      loss: 0,
      tie: 0,
    },
    season_winner_settings: 'point',
    sheet_price: {
      preseason: 0,
      inseason: 0,
    },
    bucket_mgmt: {
      total: 0,
      administrative_amount: '',
      weekly: {
        amount: 0,
        first_place: 0,
        second_place: {
          is_valid: 0,
          amount: 0,
        },
      },
      season: {
        amount: 0,
        first_place: 0,
        second_place: {
          is_valid: 0,
          amount: 0,
        },
        third_place: {
          is_valid: 0,
          amount: 0,
        },
        last_place: {
          is_valid: 0,
        },
      },
    },
  });

  const steps = [PoolGeneralStep, PoolFinancialStep, PoolCompleteStep];

  const constructFormData = form => {
    form.type = 'qp3';
    return form;
  };

  const onPoolSubmit = async () => {
    try {
      setFetching(true);
      const body = constructFormData(form);
      const endpoint = id ? '/pool/update' : '/pool/create';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar('Pool successfully created', { variant: 'success' });
      history.push('/pools/qp3');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const getPoolData = async id => {
    try {
      setFetching(true);
      const { data } = await Api.get(`/pool/${id}`);
      setForm({ ...data.pool_config, poolId: id });
      setMatchStarted(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/courses/all');
    } finally {
      setFetching(false);
    }
  };

  const isDisableNext = form => {
    if (activeStep === 0) {
      const anyChecked = Object.values(form.weekly_winner_settings).includes(1);
      return !form.name || !form.season || !anyChecked || !form.season_winner_settings;
    }
    if (activeStep === 1) {
      const { bucket_mgmt, sheet_price } = form;
      const { season, weekly } = bucket_mgmt;
      return (
        !bucket_mgmt.administrative_amount ||
        !season.amount ||
        !season.first_place ||
        !weekly.amount ||
        !weekly.first_place ||
        !sheet_price.preseason ||
        !sheet_price.inseason
      );
    }
    return false;
  };

  useEffect(() => {
    if (id) getPoolData(id);
    //eslint-disable-next-line
    }, [id]);

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep];

  if (id && fetching) return <Loading className='mt-5 pt-5' />;

  return (
    <div className='pool-creation'>
      <Header isEditing={!!id} />
      <CreateFormSteps steps={steps} activeStep={activeStep} />
      <div className='py-4 px-4 mt-5 has-header'>
        <form
          className='d-flex flex-column justify-content-between creation-form-content'
          onSubmit={handleStepChange}
        >
          <div className='col-12'>
            <Step
              matchStarted={matchStarted}
              form={form}
              setForm={setForm}
              data={data}
              setData={setData}
              onPoolSubmit={onPoolSubmit}
              fetching={fetching}
            />
          </div>
          <FooterButtons
            fetching={fetching}
            onGoBackStep={onGoBackStep}
            activeStep={activeStep}
            isLastStep={isLastStep}
            disableNext={isDisableNext(form)}
          />
        </form>
      </div>
    </div>
  );
};

export default PoolsQP3New;
