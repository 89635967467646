import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import tiersImage from 'assets/tier-players.png';
import { useHistory } from 'react-router-dom';

const TierPlayersModal = ({ tierID, close, poolId }) => {
  const [fetch, setFetch] = useState(false);
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onGetPlayers = async () => {
    try {
      setFetch(true);
      const users = await Api.get(`/pool/${poolId}/winners`, { params: { id: tierID } });
      setUsers(users.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const openStats = () => {
    close();
    history.push(`/pools/survival/${poolId}/stats`);
  };

  useEffect(() => {
    onGetPlayers();
  }, []);

  const total = users?.reduce((ac, item) => ac + item.prize, 0);

  const iconTextes = {
    '-1': 'Left',
    1: 'UNDEFEATED',
  };

  const iconText = iconTextes[tierID] || `${tierID - 1} Losses`;

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <div className='tiers-image'>
          <img width='150' src={tiersImage} alt='tiers' />
          <span>{iconText}</span>
        </div>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content flex-fill'>
        <div className='col-12 user-creation-form'>
          <ul className='pb-0 mb-0 col-12'>
            <li className='form-field-group'>
              <div className='col-12 pr-3'>
                <label className='m-0 pl-0 d-flex aling-items-center justify-content-between'>
                  Players
                  {!!total && <span className='font-weight-bolder'>${total}</span>}
                </label>
              </div>
              {!users.length && !fetch && (
                <p className='text-center text-black-50'>There is no any Players</p>
              )}
              {fetch && <Loading className='mt-1' />}
              <div className='player-prize-container'>
                {!!users?.length &&
                  !fetch &&
                  users.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className='player-prize-row d-flex align-items-center justify-content-between'
                      >
                        <p className='mb-0'>{item.owner_name || item.entrance_name}</p>
                        <p className='mb-0 prize'>${item.prize}</p>
                      </div>
                    );
                  })}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className='m-footer text-center'>
        <button className={`btn see-stats-button`} onClick={openStats}>
          See Stats
        </button>
      </div>
    </div>
  );
};

export default TierPlayersModal;
