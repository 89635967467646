import React, { useEffect, useState } from 'react';
import { IconClose } from 'shared/components/Icons';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import ClickArea from 'shared/components/ClickArea';

const headers = [
  {
    name: 'Place',
    className: 'col-1',
    key: 'team_rank',
  },
  {
    name: 'Player Name',
    className: 'col-6',
    key: 'firstname',
  },
  {
    name: 'Sheet #',
    className: 'col-3',
    key: 'sheet_id',
  },
  {
    name: 'Points',
    className: 'col-2',
    key: 'swp',
  },
];

const dynamicSort = (property, order) => {
  const sortOrder = order ? 1 : -1;

  return function(a, b) {
    if (property !== 'firstname')
      return order ? a[property] - b[property] : b[property] - a[property];
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const SeasonStandingModal = ({ close, poolId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [order, setOrder] = useState({ order: false, orderKey: 'swp' });

  const getData = async () => {
    try {
      const { data } = await Api.get(`/pool/${poolId}/season-standings`, {
        params: { ...order },
      });
      setData(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleOrderChange = item => {
    if (!item.key) return;
    setOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  useEffect(() => {
    getData();
  }, [poolId]);

  const sortedData = data && data.sort(dynamicSort(order.orderKey, order.order));

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Season Standings</h5>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content overflow-auto flex-fill mt-2'>
        {!data && <Loading className='mt-2' />}
        {data && !!data.length && (
          <div className='px-8'>
            <div className='users-lists'>
              <ul className='p-0'>
                <li className='d-flex list-header align-items-center'>
                  {headers.map((item, index) => (
                    <ClickArea
                      key={item.name + index}
                      className={`text-body d-flex align-items-center ${item.className}`}
                      onClick={handleOrderChange.bind(null, item)}
                    >
                      {item.name}
                      {item.key && order.orderKey === item.key && (
                        <button
                          className={`btn ml-2 p-0 dropdown-toggle ${
                            order.order ? '' : 'rotate-180'
                          }`}
                        />
                      )}
                    </ClickArea>
                  ))}
                </li>
                {sortedData.map((item, i) => (
                  <li key={i} className='d-flex list-item no-height'>
                    <div className='pl-3 col-1'>{item.team_rank}</div>
                    <div className='col-6'>
                      {item.firstname} {item.lastname}
                    </div>
                    <div className='col-3'>{item.sheet_id}</div>
                    <div className='col-2 text-center'>{item.swp}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {data && !data.length && <div className='text-center mt-4'>No Data Found</div>}
      </div>
    </div>
  );
};

export default SeasonStandingModal;
