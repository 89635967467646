import React from 'react';
import { IconPrint, IconSearch } from 'shared/components/Icons';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';

const Filters = ({
  data,
  printFetch,
  activeType,
  setActiveType,
  search,
  setSearch,
  showNonTeamSelected,
  setShowNonTeamSelected,
  onSheetPrint,
  scrollToCurrentWeek,
}) => {
  const tierEnabled = data.pool_config.multi_tier_settings?.available;

  const customTiers = tierEnabled
    ? data.pool_config?.bucket_mgmt?.tier?.map((item, i) => ({
        name: `Tier ${i + 1}`,
        type: i + 1,
      }))
    : [];

  const filters = [
    { name: 'All', type: 'all' },
    { name: 'Undefeated', type: 0 },
    ...customTiers,
    { name: 'Out', type: -1 },
  ];

  return (
    <div className='px-8 mb-5'>
      <div className='d-flex'>
        <div className='d-flex filter-btns'>
          {filters.map((item, i) => {
            const isActive = item.type === activeType.type;
            return (
              <button
                key={i}
                onClick={() => setActiveType(item)}
                className={`btn ${isActive ? 'active' : ''}`}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <div>
          <div className='form-field-group is-checkbox d-flex align-items-center'>
            <EditingCheckBox
              name='loss'
              value={showNonTeamSelected}
              onChange={({ target }) => setShowNonTeamSelected(!showNonTeamSelected)}
              className='p-0'
              checked={showNonTeamSelected}
              editing={true}
            />
            <div className='pl-3'>
              <label className='m-0'>Remaining without current week picks</label>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div>
            <button
              className='bg-white h-40 border-dark btn fz-14 mr-2 text-primary weight-400'
              onClick={scrollToCurrentWeek}
            >
              Scroll to Current Week
            </button>
          </div>
          <div className='d-flex mr-3'>
            <div className='search-block is-dark'>
              <input
                type='text'
                className='form-control'
                placeholder='Search by Name'
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
              <IconSearch />
            </div>
          </div>
          <div>
            <button className='view-btn bg-white' disabled={printFetch} onClick={onSheetPrint}>
              <IconPrint />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
