import React from 'react';

const CreateFormSteps = ({ steps, activeStep }) => {
  return (
    <div className='d-flex create-form-steps'>
      <ul className='align-items-center d-flex'>
        {steps.map((item, index) => {
          const isActive = activeStep === index;
          const isCompleted = activeStep > index;
          return (
            <li
              key={index}
              className={`d-flex align-items-center ${isActive ? 'active' : ''} ${
                isCompleted ? 'completed' : ''
              }`}
            >
              Step {index + 1}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CreateFormSteps;
