import React, { useState } from 'react';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { selectCustomStyles } from 'configs';
import { reportOptionsQP3, weeks } from 'app/Main/configs';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import { useSelector } from 'react-redux';

const ReportExportModal = ({ close, poolId }) => {
  const account = useSelector(state => state.account);
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState({
    id: null,
    week: account.currentWeek,
    includeFinance: false,
  });

  const onExport = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/pool/${poolId}/report`, {
        params: { ...form },
      });
      window.open(data);
      close();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onSelectReportType = option => {
    setForm({ ...form, id: option.id });
  };

  const onSelectWeek = option => {
    setForm({ ...form, week: option.id });
  };

  const handleCheckBoxChange = ({ target }) => {
    const { name, checked } = target;
    const newForm = { ...form };
    newForm[name] = checked ? 1 : 0;
    setForm(newForm);
  };

  const btnDisabled = fetch || !form.id;
  const activeWeek = weeks.find(item => item.id === form.week);

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Report Export</h5>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content flex-fill'>
        <div className='col-12 user-creation-form'>
          <ul className='pb-0 mb-0 col-12'>
            <li className='form-field-group'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Report Type</label>
              </div>
              <Select
                styles={selectCustomStyles}
                className='multi-select'
                classNamePrefix='multi-select-pre'
                getOptionLabel={o => o.name}
                getOptionValue={o => o.id}
                placeholder={'Select Report'}
                onChange={onSelectReportType}
                options={reportOptionsQP3}
              />
            </li>
            {form.id === 1 && (
              <li className='form-field-group'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Week</label>
                </div>
                <Select
                  styles={selectCustomStyles}
                  className='multi-select'
                  classNamePrefix='multi-select-pre'
                  getOptionLabel={o => {
                    if (account.currentWeek === o.id) return `${o.name} (current)`;
                    return o.name;
                  }}
                  getOptionValue={o => o.id}
                  value={activeWeek}
                  placeholder={'Select Week'}
                  onChange={onSelectWeek}
                  options={weeks}
                />
              </li>
            )}
            {form.id === 2 && (
              <li className='form-field-group mt-6 is-checkbox'>
                <EditingCheckBox
                  name='includeFinance'
                  value={form.includeFinance}
                  onChange={handleCheckBoxChange}
                  className='p-0'
                  checked={form.includeFinance}
                  editing={true}
                />
                <div className='col-8 pl-0'>
                  <label className='m-0'>Include Financial Report</label>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className='m-footer creation-buttons text-center'>
        <button
          className={`btn ${!btnDisabled ? 'btn-next' : ''}`}
          disabled={btnDisabled}
          onClick={onExport}
        >
          Generate
        </button>
      </div>
    </div>
  );
};

export default ReportExportModal;
