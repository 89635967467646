import { GLOBAL } from 'configs';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';

const filters = [
  { name: 'Survival Pools', path: '/pools/survival' },
  { name: 'QP3 Pools', path: '/pools/qp3' },
];

const Header = ({ onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {!GLOBAL.IS_PRODUCTION && (
          <Link className='btn btn-invite' to='/pools/new'>
            <span className='pluse-icon'>+</span> Add New Pool
          </Link>
        )}
        <div className='align-items-center d-flex mx-3 filters'>
          {filters.map(item => (
            <NavLink key={item.path} className='mx-3' to={item.path} activeClassName='active'>
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            className='form-control'
            onChange={({ target }) => onSearch(target.value)}
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
