import React from 'react';

const Header = ({ isEditing }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex pl-3'>
        <h3 className='header-title'>Survival Pool {isEditing ? 'Editing' : 'Creation'} Wizard</h3>
      </div>
    </div>
  );
};

export default Header;
