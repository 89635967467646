import React from 'react';
import { Link } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';

const Header = ({ onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        <Link className='btn btn-invite' to='/users/new'>
          <span className='pluse-icon'>+</span> Add New Player
        </Link>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            className='form-control'
            onChange={({ target }) => onSearch(target.value)}
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
