import React from 'react';
import AsyncSelect from 'react-select/async';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';

const SheetsSelect = ({ poolId, onChange }) => {
  const { enqueueSnackbar } = useSnackbar();

  const getSheets = async (inputValue, callback) => {
    try {
      const { data } = await Api.get(`/sheet/id-list`, {
        params: {
          pool_id: poolId,
          search: inputValue,
        },
      });
      callback(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleOnChange = sheets => {
    onChange(sheets.map(i => i.sheet_id));
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={getSheets}
      defaultOptions
      getOptionLabel={o => o.sheet_id}
      getOptionValue={o => o.id}
      isMulti
      onChange={handleOnChange}
    />
  );
};

export default SheetsSelect;
