import React from 'react';

const Table = ({ data, config, className, name }) => {
  return (
    <div className={`table-box ${className}`}>
      <div className='table-header mb-4'>
        <div className='table-row d-flex'>
          {config.map((item, i) => {
            return (
              <div style={{ width: item.width }} className={item.headerClassName} key={i}>
                {item.name || name}
              </div>
            );
          })}
        </div>
      </div>
      <div className='table-body'>
        {data.map((row, i) => {
          return (
            <div className='table-row mb-6 d-flex' key={i}>
              {config.map((item, i) => {
                return (
                  <div style={{ width: item.width }} className={item.rowClassName} key={i}>
                    {item.value(row)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
