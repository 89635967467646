import { createReducer } from 'utils/reduxHelpers';
import { Api, ApiPublic, setAuthToken } from 'utils/connectors';
import { getLocalToken, removeLocalToken, saveLocalToken } from 'utils/tokenHelpers';

const ON_AUTH_SATE = '@@auth/ON_AUTH_SATE';
const ON_LOGIN = '@@auth/ON_LOGIN';
const ON_LOGOUT = '@@auth/ON_LOGOUT';

const onAuthState = payload => ({ type: ON_AUTH_SATE, payload });
const onLogin = payload => ({ type: ON_LOGIN, payload });
const onLogout = () => ({ type: ON_LOGOUT });

const isAuthHandlers = {
  [ON_AUTH_SATE]: (state, action) => action.payload,
};

const accountHandlers = {
  [ON_LOGIN]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

const clearForLogout = dispatch => {
  dispatch(onAuthState(0));
  dispatch(onLogout());
  removeLocalToken();
};

const getProfile = async () => {
  try {
    const { data } = await Api.get('/users/me/profile');
    const currentWeek = await ApiPublic.get('/common/current-week');
    data.currentWeek = currentWeek.data.current_week;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAndUpdateProfile = () => async dispatch => {
  try {
    const res = await getProfile();
    await dispatch(onLogin(res));
  } catch (err) {
    clearForLogout(dispatch);
  }
};

export const getAuthState = () => async dispatch => {
  let token = getLocalToken();

  if (token) {
    // set common headers
    setAuthToken(token, Api);

    try {
      const user = await getProfile();
      // Keep user in store
      await dispatch(onLogin(user));
      // Set Aut State 2 - is login state (Account)
      dispatch(onAuthState(2));
    } catch (err) {
      // Set Aut State 0 - is logout state (Activation)
      dispatch(onAuthState(0));
      // Set logout
      clearForLogout(dispatch);
    }
  }
  // Set Aut State 0 - is logout state (Activation)
  else dispatch(onAuthState(0));
};

export const loginUser = data => async dispatch => {
  try {
    const token = data.token;

    saveLocalToken(token);
    setAuthToken(token, Api);
    // Set User Profile
    const user = await getProfile();
    await dispatch(onLogin(user));
    // Set Aut State 2 - is login state (Account)
    dispatch(onAuthState(2));
  } catch (err) {
    // Set Aut State 0 - is logout state (Activation)
    dispatch(onAuthState(0));
    // Set logout
    clearForLogout(dispatch);
  }
};

export const logout = () => async dispatch => {
  try {
    await Api.post('/users/me/logout');
  } catch (err) {
    clearForLogout(dispatch);
  } finally {
    clearForLogout(dispatch);
  }
};

export const isAuthInitialState = null;
export const accountInitialState = null;

export const isAuthReducer = createReducer(isAuthInitialState, isAuthHandlers);
export const accountReducer = createReducer(accountInitialState, accountHandlers);
