import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { selectCustomStyles } from 'configs';
import EditingInput from 'shared/components/editingForms/EditingInput';

const numEnt = [1, 2, 3, 4, 5];

const initialForm = () => ({
  count: 1,
  user_id: undefined,
  pool_id: null,
  season: 2023,
  participants: ['', '', '', '', ''],
});

const AddPlayerModal = ({ close, poolId, users, onSuccessCreate, setUsers }) => {
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({ ...initialForm(), pool_id: poolId });

  const handleChangeEntry = (index, { target }) => {
    const { value } = target;
    const newForm = { ...form };
    newForm.participants[index] = value;
    setForm(newForm);
  };

  const onAddPlayers = async (e, resetForm) => {
    try {
      if (!form.user_id) throw new Error('Please select a player');
      if (![...form.participants].filter(i => i).length) throw new Error('Please fill entry name');
      e && e.preventDefault();
      setFetch(true);
      const body = { ...form, participants: [...form.participants] };
      body.participants = body.participants.splice(0, form.count);
      await Api.post('/participant/generate', body);
      enqueueSnackbar('Player(s) successfully added!', { variant: 'success' });
      if (resetForm) {
        setForm({ ...initialForm(), pool_id: poolId });
      } else {
        close();
      }
      if (onSuccessCreate) onSuccessCreate();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onAddAndNewHandler = async () => {
    await onAddPlayers(null, true);
  };

  const handleCreatePlayer = async name => {
    try {
      setFetch(true);
      const [firstname, ...otherPart] = name.split(' ');
      const lastname = otherPart.join(' ');
      if (!lastname) throw new Error('Please enter a first and last name');
      const form = { firstname, lastname, username: `${firstname}${lastname}`, role: 'player' };
      const { data } = await Api.post('/users/register', form);
      setUsers([...users, { label: name, value: data.user.id }]);
      setForm(form => ({ ...form, user_id: data.user.id }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onSelectUser = user => {
    setForm({ ...form, user_id: user?.value });
  };

  const btnDisabled = fetch;
  const activeUser = users.find(i => i.value === form.user_id);

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <form className='overflow-auto d-flex flex-column h-100' onSubmit={onAddPlayers}>
        <div className='m-header'>
          <h5>Add Player</h5>
          <button onClick={close} type='button' className='btn p-0 m-close'>
            <IconClose color='#909090' />
          </button>
        </div>
        <div className='m-content flex-fill overflow-auto'>
          <div className='col-12 user-creation-form px-8'>
            <ul className='p-0 mb-0 col-12'>
              <li className='form-field-group'>
                <div className='col-4 pl-0'>
                  <label className='m-0 pl-0'>Player:</label>
                </div>
                <CreatableSelect
                  key={activeUser?.value}
                  isClearable
                  styles={selectCustomStyles}
                  className='multi-select'
                  classNamePrefix='multi-select-pre'
                  formatCreateLabel={inputValue => {
                    const [firstName, ...otherPart] = inputValue.split(' ');
                    const lastname = otherPart.join(' ');
                    return `Add new user with name: "${firstName}", lastname: "${lastname}"`;
                  }}
                  placeholder={'Not Assigned'}
                  onCreateOption={handleCreatePlayer}
                  disabled={fetch}
                  onChange={onSelectUser}
                  options={users}
                  autoFocus={true}
                  value={activeUser}
                />
              </li>
              <li className='form-field-group'>
                <div className='col-4 pl-0 mb-2'>
                  <label className='m-0 pl-0'>Number of Entries:</label>
                </div>
                <div className='num-buttons'>
                  {numEnt.map(item => {
                    const isActive = form.count === item;
                    return (
                      <button
                        type='button'
                        key={item}
                        onClick={() => setForm({ ...form, count: item })}
                        className={`btn ${isActive ? 'active' : ''}`}
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>
              </li>
              {numEnt.map((item, i) => {
                if (i + 1 > form.count) return null;
                return (
                  <li className='form-field-group' key={i}>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Entry Name {i + 1}:</label>
                    </div>
                    <EditingInput
                      type='text'
                      value={form.participants[i]}
                      onChange={handleChangeEntry.bind(null, i)}
                      className='p-0'
                      placeholder='Enter Name'
                      required
                      editing={true}
                      maxLength='180'
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className='m-footer creation-buttons text-center'>
          <button
            className={`btn ${!btnDisabled ? 'btn-arrows' : ''}`}
            disabled={btnDisabled}
            type='submit'
          >
            Create
          </button>
          <button
            className={`btn ml-3 ${!btnDisabled ? 'btn-next' : ''}`}
            disabled={btnDisabled}
            onClick={onAddAndNewHandler}
            type='button'
          >
            Add & New
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPlayerModal;
