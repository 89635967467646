import React from 'react';

const SwitcherButtons = ({ buttons, activeItem, onChange, className, disabled }) => {
  const active = Boolean(activeItem);
  return (
    <div className={`amount-switcher align-items-center d-flex ${className || ''}`}>
      <div className='switch-btns type-on-off d-flex'>
        {buttons.map(item => (
          <button
            disabled={disabled}
            className={`btn ${active === item.id ? 'active' : ''}`}
            onClick={() => onChange(item.id)}
            key={item.id}
            type='button'
          >
            {item.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SwitcherButtons;
