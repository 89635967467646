import React, { useState } from 'react';
import Select from 'react-select';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import RadioBox from 'shared/components/RadioBox';
import SheetsSelect from 'shared/components/SheetsSelect';
import { selectCustomStyles } from 'configs';

const SellSheetsModal = ({ close, poolId, users, onSuccessCreate }) => {
  const [fetch, setFetch] = useState(false);
  const [sheets, setSheets] = useState([]);
  const [success, setSuccess] = useState(false);

  const [sheetState, setSheetState] = useState('true');
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    count: '',
    user_id: null,
    pool_id: poolId,
    season: 2023,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const newForm = { ...form };
    newForm[name] = value;
    setForm(newForm);
  };

  const onCreateSheet = async () => {
    try {
      setFetch(true);
      if (sheetState === 'true') {
        const { data } = await Api.post('/sheet/generate', form);
        setSuccess(data);
      } else {
        await Api.post('/sheet/assign', {
          user_id: form.user_id,
          sheets,
        });
        enqueueSnackbar('Sheet(s) successfully assigned!', { variant: 'success' });
        close();
      }
      if (onSuccessCreate) onSuccessCreate();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onDownloadSheet = () => {
    window.open(success);
    close();
  };

  const onSelectUser = user => {
    setForm({ ...form, user_id: user.id });
  };

  const onSheetStateChange = ({ target }) => {
    setSheetState(target.value);
  };

  const btnDisabled =
    fetch ||
    !form.user_id ||
    (sheetState === 'true' && !form.count) ||
    (sheetState === 'false' && !sheets.length);

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Sell Sheet</h5>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content flex-fill'>
        {!success && (
          <div className='col-12 user-creation-form'>
            <ul className='p-0 mb-0 col-12'>
              <li className='form-field-group'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Player:</label>
                </div>
                <Select
                  styles={selectCustomStyles}
                  className='multi-select'
                  classNamePrefix='multi-select-pre'
                  getOptionLabel={o => `${o.firstname} ${o.lastname}`}
                  getOptionValue={o => o.id}
                  placeholder={'Not Assigned'}
                  onChange={onSelectUser}
                  options={users}
                  autoFocus={true}
                />
              </li>
              {sheetState === 'true' && (
                <li className='form-field-group'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>Number of Sheets:</label>
                  </div>
                  <EditingInput
                    type='number'
                    name='count'
                    value={form.count}
                    onChange={handleChange}
                    className='p-0'
                    placeholder='Enter Number'
                    required
                    editing={true}
                    maxLength='180'
                  />
                </li>
              )}
            </ul>
            <div className='d-flex justify-content-center mt-6'>
              <RadioBox
                name='sheetState1'
                value='true'
                label='New Sheet'
                checked={sheetState === 'true'}
                onChange={onSheetStateChange}
              />
              <RadioBox
                name='sheetState2'
                value='false'
                label='Existing Sheet'
                checked={sheetState === 'false'}
                onChange={onSheetStateChange}
              />
            </div>
            {sheetState === 'false' && (
              <div className='user-creation-form mt-8'>
                <ul className='p-0 mb-0 col-12'>
                  <li className='form-field-group'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Existing Sheet IDs</label>
                    </div>
                    <SheetsSelect poolId={poolId} onChange={setSheets} />
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        {success && (
          <div className='text-center sheet-success h-100 justify-content-center d-flex flex-column'>
            <h4 className='text-primary mb-4'> Sheet(s) successfully created!</h4>
            <h4 className='text-gray mb-3'>You can download and print now</h4>
            <p className='text-gray fz-12'>
              Or you can download each sheet <br /> from Sheets or from User Profile pages anytime
              later
            </p>
          </div>
        )}
      </div>
      <div className='m-footer creation-buttons text-center'>
        {!success && (
          <button
            className={`btn ${!btnDisabled ? 'btn-next' : ''}`}
            disabled={btnDisabled}
            onClick={onCreateSheet}
          >
            Sell
          </button>
        )}
        {success && (
          <button className='btn btn-next' onClick={onDownloadSheet}>
            Download
          </button>
        )}
      </div>
    </div>
  );
};

export default SellSheetsModal;
