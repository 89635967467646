import React from 'react';

const ButtonFilter = ({ title, state, changeState, className }) => {
  return (
    <button
      onClick={() => changeState(e => !e)}
      className={`btn btn-filter ${state ? 'active' : ''} ${className}`}
    >
      {title}
    </button>
  );
};

export default ButtonFilter;
